import { atom } from 'recoil';

export type ZshDialogProps = {
    open: boolean;
    title?: string;
    message?: string;
    closeText?: string;
    agreeText?: string;
    funcAgree?: () => void;
}

export const zshDialog = atom<ZshDialogProps>({
    key: 'zshDialog',
    default: {
        open: false,
        title: '',
        message: '',
        closeText: '',
        agreeText: '',
        funcAgree: () => {}
    },
});