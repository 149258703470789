import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { expenseType } from '../../config/usr-constant';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import DataTable, {
  DataTableRowsProps,
  DataTableSearchInputParamsProps,
  DataTableActionsProps,
  DataTableFuncTbodyRowProps,
  DataTableActionsFuncProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps, DataTableFiltersOptionProps } from '../../components/View/DataTableFilters';
import * as _data_actions from '../../helper/dataActions';
import * as _filter from '../../helper/filter';
import * as _debug from '../../helper/debug';
import * as _usr_const from '../../config/usr-constant';
import axios from 'axios';
import { Expense, UserList, SettlementDateList } from '../../types/model';

export default function PaidedExpensesIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [userList, setUserList] = useState<DataTableFiltersOptionProps[] | undefined>(undefined);
  const [settlementDateOptions, setSettlementDateOptions] = useState<DataTableFiltersOptionProps[] | undefined>(undefined);

  // set user list
  useEffect(() => {
    if (typeof userList === "undefined") {
      axios.get(_usr_const.ApiUrl + "users/user-list")
      .then((results: any) => {
        const op: DataTableFiltersOptionProps[] = [];
        if (typeof results.data !== "undefined" && typeof results.data === "object") {
          const options: UserList[] = results.data;
          options.forEach((p: UserList) => {
            op.push({
              text: p.full_name,
              value: p.ID
            })
          })
        }
        setUserList(op);
      })
      .catch(() => {
        setUserList([])
      })
    }
  }, [userList])

  // set settlement date list
  useEffect(() => {
    if (typeof settlementDateOptions === "undefined") {
      axios.get(_usr_const.ApiUrl + "expenses/settlement-date-list")
      .then((results: any) => {
        const op: DataTableFiltersOptionProps[] = [];
        if (typeof results.data !== "undefined" && typeof results.data === "object") {
          const options: SettlementDateList[] = results.data;
          options.forEach((p: SettlementDateList) => {
            op.push({
              text: p.value,
              value: p.key
            })
          })
        }
        setSettlementDateOptions(op);
      })
      .catch(() => {
        setSettlementDateOptions([])
      })
    }
  }, [settlementDateOptions])

  const clickCell = (params: DataTableHandleClickCellProps<Expense>): void => {
    history.push(location.pathname + '/' + params.record.ID + location.search + '&extype=paided');
  }

  const handleToCopy = (params: DataTableActionsFuncProps<Expense>): void => {
    if (params.selected.length === 1) {
      history.push('/expenses/add?copy=' + params.selected[0]);
    } else {
      setFlashMessageError('項目を1つ選択してください');
    }
  }

  const confirmDelete = (params: DataTableActionsFuncProps<Expense>): void => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: DataTableActionsFuncProps<Expense>): void => {
    _data_actions.deleteData({
      deleteUrl: 'expenses/delete',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const typeColumn = (params: DataTableFuncTbodyRowProps<Expense>): JSX.Element => {
    return (
      <span>
        {
          typeof params.record.type !== "undefined" && typeof expenseType[params.record.type] !== "undefined" &&
          <span>
            {expenseType[params.record.type]}
          </span>
        }
      </span>
    );
  }

  const callbackGetData = (params: any): void => {
    axios
      .get(_usr_const.ApiUrl + 'expenses/total-price', {
        params: params,
        cancelToken: source.current.token
      })
      .then((results: any) => {
        if (results.data.total !== undefined && !unmounted.current) {
          setTotalPrice(results.data.total)
        }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      }); 
  }

  const renderTotalPrice = (): JSX.Element => {
    return (
      <div>
        {_filter.ShFilter(totalPrice, "number_format")}円
      </div>
    );
  }

  const purchaseDateValueElement = (queries: any, filter: DataTableFiltersProps): JSX.Element => {
    let isSet: boolean = false;
    let purchaseDateStart: string = '';
    let purchaseDateEnd: string = '';
    const emptyTitle: string = filter.emptyTitle === undefined ? '全て' : filter.emptyTitle;
    if (queries['purchase_date_start'] !== undefined) {
      purchaseDateStart = queries['purchase_date_start'];
    }
    if (queries['purchase_date_end'] !== undefined) {
      purchaseDateEnd = queries['purchase_date_end'];
    }
    if (purchaseDateStart !== '' || purchaseDateEnd !== '') {
      isSet = true;
    }
    return (
      <div>
        {
          isSet &&
          <span>
            {purchaseDateStart} 〜 {purchaseDateEnd}
          </span>
        }
        {
          isSet === false &&
          <span>{emptyTitle}</span>
        }
      </div>
    );
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  const rows: DataTableRowsProps<Expense>[] = [
    {
      label: '精算日',
      value: 'settlement_date',
      filter: 'YMD',
      sortField: 'settlement_date',
      align: 'left',
    },
    {
      label: '購入日',
      value: 'purchase_date',
      filter: 'YMD',
      sortField: 'purchase_date',
      align: 'left'
    },
    {
      label: '購入者',
      value: 'user_full_name',
      align: 'left'
    },
    {
      label: '種別',
      align: 'left',
      node: typeColumn
    },
    {
      label: '摘要',
      value: 'summary',
      sortField: 'summary',
      align: 'left'
    },
    {
      label: '金額',
      value: 'price',
      sortField: 'price',
      align: 'left'
    },
    {
      label: '取引先名',
      value: 'client',
      sortField: 'client',
      align: 'left'
    },
    {
      label: '証憑番号',
      value: 'voucher_number',
      sortField: 'voucher_number',
      align: 'left'
    },
    {
      label: '精算予定日',
      value: 'scheduled_settlement_date',
      filter: 'YMD',
      sortField: 'scheduled_settlement_date',
      align: 'left',
    },
    {
      label: '更新日時',
      value: 'UpdatedAt',
      filter: 'YMDHm',
      sortField: 'updated_at',
      align: 'left',
    },
  ];

  const searchInputParams: DataTableSearchInputParamsProps[] = [
    {
      title: '摘要',
      text: '部分一致',
    },
    {
      title: '取引先名',
      text: '部分一致',
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      title: '購入者',
      key: 'user_id',
      type: 'inlineSelect',
      valueType: 'number',
      options: userList
    },
    {
      title: '精算日',
      key: 'settlement_date',
      type: 'inlineSelect',
      valueType: 'string',
      options: settlementDateOptions
    },
    {
      title: '購入日',
      type: 'date',
      keys: [
        {
          title: '購入日 開始日',
          key: 'purchase_date_start',
          type: 'date'
        },
        {
          title: '購入日 終了日',
          key: 'purchase_date_end',
          type: 'date'
        }
      ],
      valueElement: purchaseDateValueElement
    },
    {
      title: '合計金額',
      infoElement: renderTotalPrice
    }
  ];

  const actions: DataTableActionsProps<Expense>[] = [
    {
      title: 'コピー',
      func: handleToCopy,
      color: 'primary',
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
    },
  ];

  return (
    <div id="paided-expenses" className="content-1">
      <DataTable<Expense>
        rows={rows}
        checkbox={true}
        jsonPath='expenses'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchInputParams={searchInputParams}
        dataTableParams={dataTableParams}
        actions={actions}
        defaultQueryValues={
          {
            type: 'paided'
          }
        }
        callbackGetData={callbackGetData}
      />
    </div>
  )
}