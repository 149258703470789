import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as _usr_const from '../../config/usr-constant';
import * as _debug from '../../helper/debug';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    drawerIn: {
      backgroundColor: '#FFF',
      padding: '20px',
    },
    drawerBtnWr: {
      textAlign: 'left',
      paddingTop: '10px',
    }
  })
);

export type SearchFilterProps = {
  key: string;
  title?: string;
  type?: 'select' | undefined;
  options?: {
    text: string;
    value: string | number;
  }[];
  emptyTitle?: string;
  getOptionUrl?: string;
  dataKey?: string;
  optionValue?: string;
  optionText?: string;
  loading?: boolean;
}

type Props = {
  searchFilters: SearchFilterProps[];
  searchValues: { [key: string]: any };
  queries: { [key: string]: any };
  setSearch: (key: any, value: any) => void;
  onSearchSubmit: (event: any) => void;
}

export default function SearchFilter({
  searchFilters,
  searchValues,
  queries,
  setSearch,
  onSearchSubmit,
}: Props) {

  const [searchAction, setSearchAction] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [filters, setFilters] = useState<SearchFilterProps[]>([]);
  const [isInit, setIsInit] = useState<boolean>(true);

  const getOption = (filter: SearchFilterProps): void => {
    if (filter.options === undefined && filter.getOptionUrl !== undefined) {
      filter.loading = true;
      axios
        .get(_usr_const.ApiUrl + filter.getOptionUrl)
        .then((results: any) => {
          let options: any = [];
          if (filter.dataKey !== undefined && results.data[filter.dataKey] !== undefined) {
            const dataKey: string = filter.dataKey;
            if (filter.optionValue !== undefined && filter.optionText !== undefined) {
              const tmpOptionValue: string = filter.optionValue;
              const tmpOptionText: string = filter.optionText;
              results.data[dataKey].forEach((el: any) => {
                options.push({
                  value: el[tmpOptionValue],
                  text: el[tmpOptionText],
                });
              });
            } else {
              if (typeof results.data[dataKey] === 'object') {
                Object.keys(results.data[dataKey]).forEach((key: any) => {
                  options.push({
                    value: key,
                    text: results.data[dataKey][key],
                  });
                });
              } else {
                results.data[dataKey].forEach((value: any, key: any) => {
                  options.push({
                    value: key,
                    text: value,
                  });
                });
              }
            }
          }
          filter.options = options;
        })
        .catch((error: any) => {
          _debug.debugAxiosError(error);
        })
        .finally(() => {
          filter.loading = false;
        });
    }
  }

  const handleChange = (event: any, key: string): void => {
    setSearch(key, event.target.value);
  }

  const handleChangeFilter = (event: any): void => {
    setDrawer(false);
    onSearchSubmit(event);
  }

  useEffect(() => {
    if (isInit) {
      // format filter
      searchFilters.forEach((filter: SearchFilterProps) => {
        // get option
        if (filter.getOptionUrl !== undefined) {
          getOption(filter);
        }
        // set url values
        if (searchValues[filter.key] !== undefined && searchValues[filter.key] !== '') {
          setSearchAction(true);
          setSearch(filter.key, searchValues[filter.key]);
        }
        // set empty text
        if (filter.emptyTitle === undefined) {
          filter.emptyTitle = '全て';
        }
      });
      setFilters(searchFilters);
      setIsInit(false);
    }
  }, [searchFilters, isInit, searchValues, setSearch]);

  useEffect(() => {
    let filterSelected: boolean = false
    filters.forEach((fil: SearchFilterProps) => {
      if (typeof queries[fil.key] !== 'undefined' && queries[fil.key] !== '') {
        filterSelected = true
      }
    });
    setSearchAction(filterSelected);
  }, [queries, filters]);

  const classes = useStyles();

  const fliterSelects = filters.map((filter: SearchFilterProps): JSX.Element => (
    <div key={filter.key} className="search-filter-group">
      <div className="search-filter-title">{filter.title}</div>
      <div className="search-filter-input">
        {
          filter.type === 'select' &&
          <FormControl>
            <Select
              native
              value={searchValues[filter.key]}
              onChange={(event) => handleChange(event, filter.key)}
              inputProps={{
                name: filter.key,
                id: filter.key,
              }}
            >
              <option value="">{filter.emptyTitle}</option>
              {
                filter.options !== undefined &&
                filter.options.map((option: any) => (
                  <option value={option.value} key={option.value}>{option.text}</option>
                ))
              }
            </Select>
          </FormControl>
        }
      </div>
    </div>
  ))

  return (
    <div className="search-filter">
      <IconButton
        className={classes.button}
        aria-label="Search"
        onClick={() => setDrawer(true)}
        color={(() => {
          if (searchAction) {
            return 'primary';
          }
          return 'default';
        })()}
      >
        <FilterListIcon />
      </IconButton>
      <Drawer
        anchor="top"
        open={drawer}
        onClose={() => setDrawer(false)}>
        <div className={classes.drawerIn}>
          <div className="search-filter-select">
            {fliterSelects}
          </div>
          <div className={classes.drawerBtnWr}>
            <Button
              variant="contained"
              style={{ marginRight: '15px' }}
              onClick={() => setDrawer(false)}
            >
              キャンセル
              </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => handleChangeFilter(event)}
            >
              検索
              </Button>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
