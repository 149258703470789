import moment from 'moment';

/**
 * ShFilter
 * 
 * @param d 
 * @param filter 
 */
export function ShFilter(
    d: any,
    filter: 'YMDHms' | 'YMDHm' | 'YMD' | 'YM' | 'YYYY' | 'MM' | 'DD' | 'number_format' | undefined
): string {
    if (filter === undefined) {
        return d;
    }
    if (d === null || d === ''|| d === '0001-01-01T00:00:00Z') {
        return '';
    }
    let results: string = '';
    switch (filter) {

        case 'YMDHms':
        results = moment(d).format('YYYY/MM/DD HH:mm:ss');
            break;

        case 'YMDHm':
        results = moment(d).format('YYYY/MM/DD HH:mm');
            break;
        
        case 'YMD':
        results = moment(d).format('YYYY/MM/DD');
            break;

        case 'YM':
        results = moment(d).format('YYYY/MM');
            break;

        case 'YYYY':
        results = moment(d).format('YYYY');
            break;

        case 'MM':
        results = moment(d).format('MM');
            break;

        case 'DD':
        results = moment(d).format('DD');
            break;

        case 'number_format':
        results = d.toLocaleString();
            break;

        default:
            break;
    }
    return results;
}