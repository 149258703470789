const initialState = {
  isFetching: false,
  isLoded: false,
  type: '',
  roles: {},
  authority: 0,
}

export default function AuthRoles(state = [initialState], action: any): any {
  
  switch (action.type) {
    case 'GET_AUTH_ROLES_REQUEST':
      return [
        {
          isFetching: true,
          isLoded: false,
          roles: {},
          authority: 0,
        }
      ];
    case 'GET_AUTH_ROLES_SUCCESS':
      return [
        {
          isFetching: false,
          isLoded: true,
          roles: action.data.roles,
          authority: action.data.authority,
          receivedAt: action.receivedAt,
        }
      ];
    case 'GET_AUTH_ROLES_FAILURE':
      return [
        {
          isFetching: false,
          isLoded: true,
          error: action.error,
        }
      ];
    default:
      return state;
  }
}