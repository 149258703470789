import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as _roleHelper from '../../helper/role';

type HeadButtonGroupsButtons = {
  btnType: 'default' | 'submit';
  text: string;
  color: 'primary' | 'default' | 'inherit' | 'secondary';
  func?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
}

type HeadButtonGroupBtnActionRoleProps = {
  name: string;
  action: string;
}

export type HeadButtonGroupBtnActionsProps = {
  type?: 'add' | 'edit' | 'save' | undefined;
  text?: string;
  color?: 'primary' | 'default' | 'inherit' | 'secondary' | undefined;
  role?: HeadButtonGroupBtnActionRoleProps;
  func?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
}

type HeadButtonGroupsProps = {
  history: any;
  btnActions?: HeadButtonGroupBtnActionsProps[];
  hideBack?: boolean;
}

export default function HeadButtonGroups({
  history,
  btnActions,
  hideBack = false
}: HeadButtonGroupsProps) {

  const AuthRoles = useSelector(state => state.AuthRoles);

  const [buttons, setButtons] = useState<HeadButtonGroupsButtons[]>([]);

  const historyBack = (): void => {
    history.goBack();
  }

  useEffect(() => {
    let buttons: HeadButtonGroupsButtons[] = [];
    if (btnActions !== undefined) {
      btnActions.forEach((element: any) => {
        // set text and color and btnType
        element.btnType = 'default';
        if (element.type !== undefined) {
          switch (element.type) {

            case 'add':
              element.text = '追加';
              element.color = 'primary';
              break;

            case 'edit':
              element.text = '編集';
              element.color = 'primary';
              break;

            case 'save':
              element.text = '保存';
              element.color = 'primary';
              element.btnType = 'submit';
              break;

            default:
              break;
          }
        }
        if (element.color === undefined) {
          element.color = 'default';
        }
        if (element.text === undefined) {
          element.text = '';
        }
        // check role
        if (element.role === undefined) {
          buttons.push(element);
        } else {
          if (_roleHelper.checkRole(element.role, AuthRoles)) {
            buttons.push(element);
          }
        }
      });
    }
    setButtons(buttons);
  }, [AuthRoles, btnActions]);

  return (
    <div className="btn-group">
      {
        hideBack === false &&
        <span>
          <Button variant="contained" className="btn" onClick={historyBack}>戻る</Button>
        </span>
      }
      {
        buttons.map((action: any, index: number) => (
          <span key={index}>
            {
              action.btnType === 'default' &&
              <Button
                variant="contained"
                color={action.color}
                className="btn"
                onClick={action.func}
              >
                {action.text}
              </Button>
            }
            {
              action.btnType === 'submit' &&
              <Button
                type="submit"
                variant="contained"
                color={action.color}
                className="btn"
              >
                {action.text}
              </Button>
            }
          </span>
        ))
      }
    </div>
  )
}