import React, { useRef, useState , useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageError } from '../../atoms/FlashMessage';
import DataTable, {
  DataTableRowsProps,
  DataTableActionsProps,
  DataTableActionsFuncProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps, DataTableFiltersOptionProps } from '../../components/View/DataTableFilters';
import * as _data_actions from '../../helper/dataActions';
import axios from 'axios';
import * as _usr_const from '../../config/usr-constant';
import { Expense, SettlementDateFilterList } from '../../types/model';

export default function TaxProcessingsIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const sourceDownloadCsv = useRef(axios.CancelToken.source());

  const [settlementDateFilterOptions, setSettlementDateFilterOptions] = useState<DataTableFiltersOptionProps[] | undefined>(undefined);

  // set settlement date list
  useEffect(() => {
    if (typeof settlementDateFilterOptions === "undefined") {
      axios.get(_usr_const.ApiUrl + "expenses/settlement-date-filter-list")
      .then((results: any) => {
        const op: DataTableFiltersOptionProps[] = [];
        if (typeof results.data !== "undefined" && typeof results.data === "object") {
          const options: SettlementDateFilterList[] = results.data;
          options.forEach((p: SettlementDateFilterList) => {
            op.push({
              text: p.value,
              value: p.key
            })
          })
        }
        setSettlementDateFilterOptions(op);
      })
      .catch(() => {
        setSettlementDateFilterOptions([])
      })
    }
  }, [settlementDateFilterOptions])

  const clickCell = (params: DataTableHandleClickCellProps<Expense>): void => {
    history.push('expenses/' + params.record.ID + location.search);
  }

  const handleDownloadCsv = (params: DataTableActionsFuncProps<Expense>): void => {
    _data_actions.downloadCsv({
      downloadUrl: 'expenses/tax-processings-download-csv',
      fileName: 'expenses_tax_processings',
      source: sourceDownloadCsv,
      queryParams: params.query,
      setOverlayProppress,
      setFlashMessageError
    });
  }

  const rows: DataTableRowsProps<Expense>[] = [
    {
      label: '精算日',
      value: 'settlement_date',
      filter: 'YMD',
      sortField: 'settlement_date',
      align: 'left',
    },
    {
      label: '証憑番号',
      value: 'voucher_number',
      sortField: 'voucher_number',
      align: 'left'
    },
    {
      label: '',
      value: '_blank',
      align: 'left'
    },
    {
      label: '勘定科目',
      value: 'expense_item.account',
      align: 'left'
    },
    {
      label: '相手科目',
      value: 'expense_item.subject',
      align: 'left'
    },
    {
      label: '',
      value: '_blank',
      align: 'left'
    },
    {
      label: '金額',
      value: 'price',
      filter: 'number_format',
      sortField: 'price',
      align: 'left'
    },
    {
      label: '',
      value: '_blank',
      align: 'left'
    },
    {
      label: '取引先名',
      value: 'client',
      sortField: 'client',
      align: 'left'
    },
    {
      label: '年',
      value: 'purchase_date',
      filter: 'YYYY',
      align: 'left',
    },
    {
      label: '月',
      value: 'purchase_date',
      filter: 'MM',
      align: 'left',
    },
    {
      label: '日',
      value: 'purchase_date',
      filter: 'DD',
      align: 'left',
    },
    {
      label: '摘要',
      value: 'summary',
      sortField: 'summary',
      align: 'left'
    },
    {
      label: '購入者',
      value: 'user_full_name',
      align: 'left'
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      title: '精算日',
      key: 'settlement_date_ym',
      type: 'inlineSelect',
      options: settlementDateFilterOptions
    }
  ];

  const actions: DataTableActionsProps<Expense>[] = [
    {
      title: 'CSV出力',
      func: handleDownloadCsv,
      color: 'primary',
    },
  ];

  return (
    <div id="tax-processings" className="content-1">
      <DataTable<Expense>
        rows={rows}
        checkbox={true}
        jsonPath='expenses'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        dataTableParams={dataTableParams}
        actions={actions}
        defaultQueryValues={{
          action: "tax_processings"
        }}
      />
    </div>
  )
}