export function debugAxiosError(error: any): void {
  if (typeof error.config !== 'undefined' && typeof error.config.url !== 'undefined') {
    console.log('ErrorUrl:' + error.config.url);
  }
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    // auth error
    if (error.response.status === 401) {
      window.location.href = '/users/login';
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    if (typeof error.message !== 'undefined') {
      console.log('Error:', error.message); 
    }
  }
}