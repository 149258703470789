import React from 'react';

class notRole extends React.Component {
    render() {
        return (
            <div>
              権限がありません
            </div>
        )
    }
}

export default notRole;