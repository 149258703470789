import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as _const from '../config/constant';
import * as AuthRolesActions from '../actions/AuthRoles';
import { MuiThemeProvider, createMuiTheme, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import CircularProgress from '@material-ui/core/CircularProgress';
import ZshDialog from '../components/ZshDialog';
import OverlayProgress from '../components/OverlayProgress';

let theme = createMuiTheme({
  typography: {
    // useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth: number = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      transition: 'all 0.5s ease',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    mainContent: {
      flex: 1,
      padding: '25px',
      background: '#eaeff1',
    },
  })
);

function Paperbase({ location, history }: RouteComponentProps) {

  const dispatch = useDispatch();

  const AuthInformations: any = useSelector(state => state.AuthInformations);
  const AuthRoles: any = useSelector(state => state.AuthRoles);

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [menus, setMenus] = useState<_const.MenuType[]>([]);
  const [isSetAuthInformations, setIsSetAuthInformations] = useState<boolean>(false);
  const [isInit, setInit] = useState<boolean>(true);
  const [pathname, setPathname] = useState<string>(location.pathname);

  const rootAuthRoles = useRef<any>({});
  const Authority = useRef<number>(0);

  const formatMenus = useCallback(() => {
    if (location.pathname !== undefined) {
      AuthRoles.forEach((element: any) => {
        if (element.isLoded) {
          rootAuthRoles.current = element.roles;
          Authority.current = element.authority;
        }
      });
      if (rootAuthRoles.current != null && Object.keys(rootAuthRoles.current).length > 0) {
        let tmpConstMenus: _const.MenuType[] = _const.Menus;
        tmpConstMenus.forEach((element: any) => {
          let thisParentAllow: boolean = false;
          if (element.authority === undefined) {
            thisParentAllow = true;
          } else {
            if (Authority.current >= element.authority) {
              thisParentAllow = true;
            }
          }
          element.show = thisParentAllow;
          element['children'].forEach((child: any) => {
            if (child.to === undefined) {
              child.show = false;
            } else {
              if (child.allow === false) {
                let thisAllow: boolean = true;
                const tmpRoles: any = rootAuthRoles.current;
                if (tmpRoles[child.name] === undefined) {
                  thisAllow = false;
                } else if (tmpRoles[child.name].indexOf('view') === -1) {
                  thisAllow = false;
                }
                child.allow = thisAllow;
              }
              if (child.allow) {
                child.show = true;
              } else {
                child.show = false;
              }
            }
          });
        });
        setMenus(tmpConstMenus);
      }
    }
  }, [location, AuthRoles]);

  const getPageTitle = (): string => {
    let pageTitile: string = '';
    // set this path name
    let locationPath: string = '';
    if (location.pathname !== undefined) {
      const splitLocationPath = location.pathname.split('/');
      locationPath = '/' + splitLocationPath[1];
    }
    _const.Menus.forEach((element: any) => {
      element['children'].forEach((child: any) => {
        if (child.to === locationPath) {
          pageTitile = child.title;
        }
      });
    })
    return pageTitile;
  }

  const checkAuthInformationsLoaded = useCallback(() => {
    let result: boolean = false;
    if (rootAuthRoles.current != null && Object.keys(rootAuthRoles.current).length > 0) {
      if (AuthInformations !== undefined) {
        AuthInformations.forEach((element: any) => {
          if (element.isLoded) {
            result = true;
          }
        });
      }
    }
    result = true;
    return result;
  }, [rootAuthRoles, AuthInformations]);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = (): void => {
    setMobileOpen(false)
  }

  useEffect(() => {
    if (isInit) {
      if (location.pathname === '/') {
        history.push('/expenses');
      }
      dispatch(AuthRolesActions.getAuthRoles());
      setInit(false);
    }
  }, [isInit, history, location.pathname, dispatch]);

  useEffect(() => {
    // change pathname get roles
    if (pathname !== location.pathname) {
      dispatch(AuthRolesActions.getAuthRoles());
      formatMenus();
      setPathname(location.pathname);
    }
    // init format menus
    if (Object.keys(rootAuthRoles.current).length === 0) {
      formatMenus();
    }
    if (isSetAuthInformations === false) {
      if (checkAuthInformationsLoaded()) {
        setIsSetAuthInformations(true);
      }
    }
  },
    [
      pathname,
      location,
      rootAuthRoles,
      isSetAuthInformations,
      formatMenus,
      checkAuthInformationsLoaded,
      dispatch
    ]
  );

  const classes = useStyles();
  const authLoadingClass: 'auth-loaded' | 'auth-loading' = isSetAuthInformations ? 'auth-loaded' : 'auth-loading';

  return (
    <MuiThemeProvider theme={theme}>
      <ZshDialog />
      <OverlayProgress />
      <div className={authLoadingClass}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                menus={menus}
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                handleDrawerClose={handleDrawerClose}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator
                menus={menus}
                variant="permanent"
                PaperProps={{ style: { width: drawerWidth } }}
                handleDrawerClose={handleDrawerClose}
              />
            </Hidden>
          </nav>
          <div className={classes.appContent}>
            <Header
              onDrawerToggle={handleDrawerToggle}
              pageTitle={getPageTitle()}
            />
            <main className={classes.mainContent}>
              <Content
                authRoles={rootAuthRoles.current}
              />
            </main>
          </div>
        </div>
      </div>
      {
        isSetAuthInformations === false &&
        <div className="auth-loading-progress">
          <CircularProgress />
        </div>
      }
    </MuiThemeProvider>
  );
}
export default withRouter(Paperbase);