import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, {
  DataTableRowsProps,
  DataTableSearchInputParamsProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps } from '../../components/View/DataTableFilters';
import { Log } from '../../types/model';

export default function LogsIndex({ history, location }: RouteComponentProps) {

  const clickCell = (callbackParams: DataTableHandleClickCellProps<Log>): void => {
    history.push(location.pathname + '/' + callbackParams.record.ID + location.search);
  }

  const rows: DataTableRowsProps<Log>[] = [
    {
      label: 'ログ日時',
      value: 'CreatedAt',
      filter: 'YMDHm',
      sortField: 'created_at',
      align: 'left',
    },
    {
      label: 'ログメッセージ',
      value: 'message',
      align: 'left'
    },
    {
      label: 'ログレベル',
      value: 'level',
      sortField: 'level',
      align: 'left',
    },
    {
      label: '入力ID',
      value: 'param1',
      align: 'left',
    },
    {
      label: 'ユーザー名',
      value: 'user_name',
      align: 'left',
    },
  ];

  const searchInputParams: DataTableSearchInputParamsProps[] = [
    {
      title: '入力ID',
      text: '部分一致',
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      key: 'level',
      title: 'ログレベル',
      type: 'inlineSelect',
      emptyTitle: 'ALL',
      options: [
        {
          text: 'INFO',
          value: 'INFO',
        },
        {
          text: 'WARN',
          value: 'WARN',
        },
      ],
    },
  ];

  return (
    <div id="logs" className="content-1">
      <DataTable<Log>
        rows={rows}
        checkbox={false}
        jsonPath='login-logs'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchInputParams={searchInputParams}
        dataTableParams={dataTableParams}
      />
    </div>
  )
}