import React, { useState, useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { flashMessage } from '../atoms/FlashMessage';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(1) / 2,
    },
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

type FlashMessageProps = {
  initialOpen?: boolean;
}

export default function FlashMessage({ initialOpen = false }: FlashMessageProps) {

  const flashMessageAtom = useRecoilValue(flashMessage);
  const flashMessageRest = useResetRecoilState(flashMessage);

  const classes = useStyles();

  const formatMessage = (message: any): any => {
    if (message.indexOf('¥n') !== -1) {
      return message.split('¥n').map((line: string, index: number) => (
        <span key={index}>
          {line}<br />
        </span>
      ));
    }
    return message;
  }

  const setIconComponent = (level: any, classes: any): any => {
    let Icon: any;
    switch (level) {
      case 'warning':
        Icon = <WarningIcon className={classes.icon} />;
        break;

      case 'error':
        Icon = <ErrorIcon className={classes.icon} />;
        break;

      case 'info':
        Icon = <InfoIcon className={classes.icon} />;
        break;

      default:
        Icon = <CheckCircleIcon className={classes.icon} />;
        break;
    }
    return Icon;
  }

  const setContentStyle = (level: any, classes: any): any => {
    let contentStyle: any;
    switch (level) {
      case 'error':
        contentStyle = classes['error'];
        break;

      case 'info':
        contentStyle = classes['info'];
        break;

      case 'warning':
        contentStyle = classes['warning'];
        break;

      default:
        contentStyle = classes['success'];
        break;
    }
    return contentStyle;
  }

  const [state, setState] = useState({
    open: initialOpen,
    message: formatMessage(flashMessageAtom.message),
    contentStyle: setContentStyle(flashMessageAtom.level, classes),
    icon: setIconComponent(flashMessageAtom.level, classes)
  });

  const handleClose = (event: any): void => {
    flashMessageRest();
    setState({
      open: false,
      message: '',
      contentStyle: setContentStyle(flashMessageAtom.level, classes),
      icon: setIconComponent(flashMessageAtom.level, classes)
    });
  };

  useEffect(() => {
    if (state.open === false) {
      if (flashMessageAtom.message !== '') {
        setState({
          open: true,
          message: formatMessage(flashMessageAtom.message),
          contentStyle: setContentStyle(flashMessageAtom.level, classes),
          icon: setIconComponent(flashMessageAtom.level, classes)
        });
      }
    }
  }, [state, flashMessageAtom, classes, setState]);

  return (
    <div className="zsh-flash-message">
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={state.open}
        autoHideDuration={15000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          className={state.contentStyle}
          message={
            <span id="flash-message-content" className={classes.message}>
              {state.icon}
              <span>
                <React.Fragment>
                  {state.message}
                </React.Fragment>
              </span>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
}