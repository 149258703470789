import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

type Props = {
  loading: boolean;
}

function Loading({ loading }: Props) {
  const loadingStyle = {
    marginTop: '15px',
  };
  return (
    <div>
      {
        loading &&
        <LinearProgress style={loadingStyle} />
      }
    </div>
  )
}
export default Loading;