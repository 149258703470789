import React, { useRef, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug'
import * as _filter from '../../helper/filter';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import MultiRowText from '../../components/View/MultiRowText'
import { Expense } from '../../types/model';
import queryString from 'query-string';

export default function ExpensesView({ history, location, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Expense>({
    ID: 0,
    CreatedAt: '',
    UpdatedAt: '',
    DeletedAt: null,
    parent_id: 0,
    user_id: 0,
    uuid: '',
    expense_item_id: 0,
    purchase_date: '',
    summary: '',
    price: 0,
    scheduled_settlement_date: '',
    settlement_date: '',
    client: '',
    voucher_number: '',
    remarks: '',
    type: '',
    user_full_name: '',
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<Expense>({
        unmounted,
        source,
        path: _usr_const.ApiUrl + 'expenses/' + match.params.id,
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  // set btn actions
  const btnActions: HeadButtonGroupBtnActionsProps[] = [];

  const allowEditTypes: {[key: string]: string} = {
    "index": '/expenses/' + match.params.id + '/edit',
    "scheduled": '/scheduled-expenses/' + match.params.id + '/edit?extype=scheduled'
  };
  
  let isType: string = "index";
  
  const parentDataParams = queryString.parse(location.search);

  if (
    typeof parentDataParams['extype'] !== 'undefined' &&
    parentDataParams['extype'] !== null &&
    typeof parentDataParams['extype'] === "string"
  ) {
    isType = parentDataParams['extype']
  }

  if (typeof allowEditTypes[isType] !== "undefined") {
    btnActions.push(
      {
        type: 'edit',
        func: (): void => {
          history.push(allowEditTypes[isType]);
        },
      }
    );
  }

  return (
    <div id="expenses" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.ID}</td>
                  </tr>
                  <tr>
                    <th>購入日</th>
                    <td>{_filter.ShFilter(data.purchase_date, "YMD")}</td>
                  </tr>
                  <tr>
                    <th>購入者</th>
                    <td>{data.user_full_name}</td>
                  </tr>
                  <tr>
                    <th>摘要</th>
                    <td>{data.summary}</td>
                  </tr>
                  <tr>
                    <th>金額</th>
                    <td>{_filter.ShFilter(data.price, "number_format")}</td>
                  </tr>
                  <tr>
                    <th>取引先名</th>
                    <td>{data.client}</td>
                  </tr>
                  <tr>
                    <th>証憑番号</th>
                    <td>{data.voucher_number}</td>
                  </tr>
                  <tr>
                    <th>精算予定日</th>
                    <td>{_filter.ShFilter(data.scheduled_settlement_date, "YMD")}</td>
                  </tr>
                  <tr>
                    <th>精算日</th>
                    <td>{_filter.ShFilter(data.settlement_date, "YMD")}</td>
                  </tr>
                  <tr>
                    <th>勘定科目</th>
                    <td>
                      {
                        typeof data.expense_item !== "undefined" && data.expense_item !== null &&
                        <span>{data.expense_item.account}</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>相手科目</th>
                    <td>
                      {
                        typeof data.expense_item !== "undefined" && data.expense_item !== null &&
                        <span>{data.expense_item.subject}</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{<MultiRowText text={data.remarks} />}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          {
            data.logs !== undefined && data.logs !== null &&
            <ZshLogs logs={data.logs} />
          }
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}