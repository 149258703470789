import React from 'react';
import Button from '@material-ui/core/Button';

type ErrorViewProps = {
  history: any;
  errorStatus: number;
  errorMsg: string;
}

export default  function ErrorView({ errorStatus = 0, errorMsg, history }: ErrorViewProps) {

  const historyBack = (): void => {
    history.goBack();
  }

  return (
    <div className="error-view">
      <p className={`error-status-${errorStatus}`}>{errorMsg}</p>
      <Button variant="contained" className="btn" onClick={historyBack}>戻る</Button>
    </div>
  )
}