import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _expensesFormFunc from './func/ExpensesForm'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshDateTimePicker from '../../components/Form/ZshDateTimePicker';
import axios from 'axios';
import ExpensesClients from './ExpensesClients';
import { ExpensesFormDataProps } from './ExpensesAdd';
import queryString from 'query-string';

export default function ExpensesEdit({ history, match, location }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName: string = 'expenses/';
  const backActionName: string = '/expenses';

  const dataSource = useRef(axios.CancelToken.source());
  const sourceUserLIst = useRef(axios.CancelToken.source());
  const unmounted = useRef<boolean>(false);

  const [isInit, setInit] = useState<boolean>(true);
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<ExpensesFormDataProps>({
    user_id: '',
    expense_item_id: '',
    purchase_date: null,
    summary: '',
    price: '',
    scheduled_settlement_date: null,
    settlement_date: null,
    client: '',
    remarks: '',
    pickers: {
      purchase_date: null,
      scheduled_settlement_date: null,
      settlement_date: null,
    },
  });
  const [validateErrors, setValidateErrors] = useState<{ [key: string]: string[] }>({});
  const [userList, setUserList] = useState<{ [key: number]: string }>({});
  const [userListError, setUserListError] = useState<boolean>(false);
  const [expenseItemList, setExpenseItemList] = useState<{ [key: number]: string }>({});
  const [expenseItemListError, setExpenseItemListError] = useState<boolean>(false);
  const [showClients, setShowClients] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleDatePickerChange = (date: Date | null, name: string, type: string): void => {
    const tmpFormData = _form.setDatePickerValue({formData, date, name, type});
    setFormData(tmpFormData);
  }

  const handleSelectClient = (client: string): void => {
    setFormData({ ...formData, client });
    setShowClients(false);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    let submitBackAction: string = backActionName;
    const parentDataParams = queryString.parse(location.search);
    if (
      typeof parentDataParams['extype'] !== 'undefined' &&
      typeof parentDataParams['extype'] === "string" &&
      parentDataParams['extype'] === "scheduled"
    ) {
      submitBackAction = "/scheduled-expenses"
    }
    _form.handleSubmit({
      action: actionName + match.params.id,
      method: "put",
      formData,
      history,
      backActionName: submitBackAction,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    const ulSource = Object.assign({}, sourceUserLIst.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      ulSource.cancel();
      clDSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await _expensesFormFunc.getUserList({
          unmounted: unmounted.current,
          setUserList,
          setUserListError,
          source: sourceUserLIst
        });
        await _expensesFormFunc.getExpenseItemList({
          unmounted: unmounted.current,
          setExpenseItemList,
          setExpenseItemListError,
          source: sourceUserLIst
        });
        if (dataMounted === false) {
          const tepFormData: ExpensesFormDataProps = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + actionName + match.params.id, {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                const formData = _form.setFormData(tepFormData, results.data);
                setDataMounted(true);
                setFormData(formData);
              }
            })
            .finally(() => {
              return Promise.resolve();
            });
        }
        await ((): any => {
          setLoading(false);
          return Promise.resolve();
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="expenses" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">購入日</th>
                      <td>
                        <div className="form-input-group">
                          <ZshDateTimePicker
                          name="purchase_date"
                          format="YYYY/MM/DD"
                          views={['date']}
                          value={formData.pickers.purchase_date}
                          handleChange={(date: Date | null) => handleDatePickerChange(date, 'purchase_date', 'date')}
                          validateErrors={validateErrors}
                          required
                          type="date"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">購入者</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                          menuItems={userList}
                          name="user_id"
                          formData={formData}
                          validateErrors={validateErrors}
                          handleChange={handleChange}
                          getError={userListError}
                          required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">摘要</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.summary}
                            name="summary"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">金額</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.price}
                            name="price"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                            type="number"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>取引先名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.client}
                            name="client"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            autoComplete="off"
                          />
                          <Button
                          size="small"
                          variant="outlined"
                          className="zsh-input-btn"
                          onClick={() => setShowClients(true)}
                          >
                            選択
                          </Button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>精算予定日</th>
                      <td>
                        <div className="form-input-group">
                          <ZshDateTimePicker
                          name="scheduled_settlement_date"
                          format="YYYY/MM/DD"
                          views={['date']}
                          value={formData.pickers.scheduled_settlement_date}
                          handleChange={(date: Date | null) => handleDatePickerChange(date, 'scheduled_settlement_date', 'date')}
                          validateErrors={validateErrors}
                          type="date"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>精算日</th>
                      <td>
                        <div className="form-input-group">
                          <ZshDateTimePicker
                          name="settlement_date"
                          format="YYYY/MM/DD"
                          views={['date']}
                          value={formData.pickers.settlement_date}
                          handleChange={(date: Date | null) => handleDatePickerChange(date, 'settlement_date', 'date')}
                          validateErrors={validateErrors}
                          type="date"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>経費科目</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                          menuItems={expenseItemList}
                          name="expense_item_id"
                          formData={formData}
                          validateErrors={validateErrors}
                          handleChange={handleChange}
                          getError={expenseItemListError}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>備考</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.remarks}
                            name="remarks"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                            multiline={true}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
      <ExpensesClients
      open={showClients}
      setShowClients={setShowClients}
      handleSelectClient={handleSelectClient}
      />
    </div>
  )
}