import axios from 'axios';
import * as _debug from '../helper/debug';

type getViewDataProps<T> = {
  unmounted: React.MutableRefObject<boolean>;
  source: any;
  path: string;
  setData: React.Dispatch<React.SetStateAction<T>>;
  setDataMounted: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorStatus: React.Dispatch<React.SetStateAction<number>>;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  successCallbackBeforeSetData?: (results: any) => void | undefined;
  successCallbackAftereSetData?: (results: any) => void | undefined;
}

export async function getViewData<T extends {}>({
  unmounted,
  source,
  path,
  setData,
  setDataMounted,
  setErrorStatus,
  setErrorMsg,
  setLoading,
  successCallbackBeforeSetData,
  successCallbackAftereSetData
}: getViewDataProps<T>) {
  try {
    await axios
      .get(path, {
        cancelToken: source.current.token
      })
      .then((results: any) => {
        if (!unmounted.current) {
          if (successCallbackBeforeSetData !== undefined) {
            successCallbackBeforeSetData(results);
          }
          if (results.data !== null) {
            setDataMounted(true);
            setData({...results.data}); 
          }
          if (successCallbackAftereSetData !== undefined) {
            successCallbackAftereSetData(results);
          }
        }
        return Promise.resolve(results);
      })
      .catch((error: any) => {
        _debug.debugAxiosError(error);
        if (error.response) {
          setErrorStatus(error.response.status);
          setErrorMsg(error.response.data.message);
        }
      })
      .finally(() => {
        if (!unmounted.current) {
          setLoading(false);
        }
      });
  } catch (error) {
    _debug.debugAxiosError(error);
    if (error.response) {
      setErrorStatus(error.response.status);
      setErrorMsg(error.response.data.message);
    }
    throw error;
  }
}