import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

type ZshTextFieldProps = {
  label?: string;
  value: any;
  name: string;
  handleChange: (event: any) => void
  margin?: 'normal' | 'none' | 'dense' | undefined;
  className?: string;
  required?: boolean;
  fullWidth?: boolean;
  type?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  validateErrors?: { [key: string]: string[] };
}

export default function ZshTextField({
  label = '',
  value,
  name,
  handleChange,
  margin = 'normal',
  className = 'form-input',
  required = false,
  fullWidth = false,
  type = 'text',
  autoComplete = '',
  autoFocus = false,
  multiline = false,
  validateErrors
}: ZshTextFieldProps) {

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <span className="zsh-text-field">
      <TextField
        error={errorMessages.length > 0}
        label={label}
        value={value}
        name={name}
        onChange={handleChange}
        margin={margin}
        className={className}
        fullWidth={fullWidth}
        required={required}
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        multiline={multiline}
        helperText={(() => {
          if (errorMessages.length > 0) {
            return (
              <span className="zsh-text-field-er-wr">
                {
                  errorMessages.map((msg: string, index: number) => (
                    <span key={name + '-erMsg-' + index} className="zsh-text-field-er-msg">
                      <span>{msg}</span><br />
                    </span>
                  ))
                }
              </span>
            );
          }
        })()}
      />
    </span>
  )
}