const initialState = {
  isFetching: false,
  isLoded: false,
  type: '',
  data: {},
}

export default function AuthInformations(state = [initialState], action: any): any {
  
  switch (action.type) {
    case 'GET_AUTH_REQUEST':
        return [
          ...state,
          {
            isFetching: true,
            isLoded: false,
            data: {}
          }
        ];
    case 'GET_AUTH_SUCCESS':
        return [
          ...state,
          {
            isFetching: false,
            isLoded: true,
            data: action.data,
            receivedAt: action.receivedAt,
          }
        ];
    case 'GET_AUTH_FAILURE':
        return [
          ...state,
          {
            isFetching: false,
            isLoded: true,
            error: action.error,
          }
        ];
    default:
        return state;
  }
}