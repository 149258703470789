import * as _expenses from './view/Expenses';
import * as _unpaid_expenses from './view/UnpaidExpenses';
import * as _scheduled_expenses from './view/ScheduledExpenses';
import * as _paided_expenses from './view/PaidedExpenses';
import * as _expense_items from './view/ExpenseItems';
import * as _tax_processings from './view/TaxProcessings';
import * as _users from './view/Users';
import * as _logs from './view/Logs';

type RouterType = {
  path: string;
  component: any;
  name: string;
  roleAction: string;
}

let setRouter: RouterType[] = [];

/**
 * Expenses
 */
setRouter.push(
  {
    path: '/',
    component: _expenses.ExpensesIndex,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/expenses',
    component: _expenses.ExpensesIndex,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/expenses/add',
    component: _expenses.ExpensesAdd,
    name: 'Expenses',
    roleAction: 'add',
  },
  {
    path: '/expenses/import-csv',
    component: _expenses.ExpensesImportCsv,
    name: 'Expenses',
    roleAction: 'csv_import',
  },
  {
    path: '/expenses/:id',
    component: _expenses.ExpensesView,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/expenses/:id/edit',
    component: _expenses.ExpensesEdit,
    name: 'Expenses',
    roleAction: 'edit',
  }
);

setRouter.push(
  {
    path: '/unpaid-expenses',
    component: _unpaid_expenses.UnpaidExpensesIndex,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/unpaid-expenses/:id',
    component: _expenses.ExpensesView,
    name: 'Expenses',
    roleAction: 'view',
  },
);

setRouter.push(
  {
    path: '/scheduled-expenses',
    component: _scheduled_expenses.ScheduledExpensesIndex,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/scheduled-expenses/:id',
    component: _expenses.ExpensesView,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/scheduled-expenses/:id/edit',
    component: _expenses.ExpensesEdit,
    name: 'Expenses',
    roleAction: 'edit',
  }
);

setRouter.push(
  {
    path: '/paided-expenses',
    component: _paided_expenses.PaidedExpensesIndex,
    name: 'Expenses',
    roleAction: 'view',
  },
  {
    path: '/paided-expenses/:id',
    component: _expenses.ExpensesView,
    name: 'Expenses',
    roleAction: 'view',
  },
);

/**
 * ExpenseItems
 */
setRouter.push(
  {
    path: '/expense-items',
    component: _expense_items.ExpenseItemsIndex,
    name: 'ExpenseItems',
    roleAction: 'view',
  },
  {
    path: '/expense-items/add',
    component: _expense_items.ExpenseItemsAdd,
    name: 'ExpenseItems',
    roleAction: 'add',
  },
  {
    path: '/expense-items/:id',
    component: _expense_items.ExpenseItemsView,
    name: 'ExpenseItems',
    roleAction: 'view',
  },
  {
    path: '/expense-items/:id/edit',
    component: _expense_items.ExpenseItemsEdit,
    name: 'ExpenseItems',
    roleAction: 'edit',
  }
);

/**
 * TaxProcessings
 */
setRouter.push(
  {
    path: '/tax-processings',
    component: _tax_processings.TaxProcessingsIndex,
    name: 'TaxProcessings',
    roleAction: 'view',
  }
);

/**
 * Users
 */
setRouter.push(
  {
    path: '/users',
    component: _users.UsersIndex,
    name: 'Users',
    roleAction: 'view',
  },
  {
    path: '/users/add',
    component: _users.UsersAdd,
    name: 'Users',
    roleAction: 'add',
  },
  {
    path: '/users/:id',
    component: _users.UsersView,
    name: 'Users',
    roleAction: 'view',
  },
  {
    path: '/users/:id/edit',
    component: _users.UsersEdit,
    name: 'Users',
    roleAction: 'edit',
  }
);

/**
 * Logs
 */
setRouter.push(
  {
    path: '/logs',
    component: _logs.LogsIndex,
    name: 'Logs',
    roleAction: 'view',
  },
  {
    path: '/logs/:id',
    component: _logs.LogsView,
    name: 'Logs',
    roleAction: 'view',
  },
);

export const router: RouterType[] = setRouter;