/**
 * checkRole
 * 
 */
export function checkRole(role: { name: string; action: string }, authRoles: any): boolean {
  if (role.name === undefined || role.action === undefined) {
    return false;
  }
  if (
    authRoles === undefined ||
    authRoles === '' ||
    authRoles === null ||
    authRoles.length === 0
    ) {
    return false;
  }
  let result: boolean = false;
  let roles: any = {};
  if (authRoles.length > 0) {
    authRoles.forEach((element: any) => {
      if (element.isLoded) {
        roles = element.roles;
      }
    });
    if (roles === undefined) {
      return result;
    }
    if (typeof roles[role.name] !== 'undefined') {
      if (roles[role.name].indexOf(role.action) !== -1) {
        result = true;
      }
    }
  }
  return result;
}