import React, { useRef, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups from '../../components/View/HeadButtonGroups';
import ErrorView from '../../components/View/ErrorView';
import { Log } from '../../types/model';

export default function LogsView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Log>({
    ID: 0,
    parent_id: 0,
    user_id: 0,
    CreatedAt: '',
    level: '',
    user_name: '',
    message: '',
    func: '',
    name: '',
    action: '',
    host: '',
    method: '',
    status: 0,
    action_type: '',
    param1: '',
    remote_ip: '',
    uri: '',
    user_agent: '',
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<Log>({
        unmounted,
        source,
        path: _usr_const.ApiUrl + 'logs/' + match.params.id,
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  return (
    <div id="logs" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.ID}</td>
                  </tr>
                  <tr>
                    <th>日時</th>
                    <td>{_filter.ShFilter(data.CreatedAt, 'YMDHms')}</td>
                  </tr>
                  <tr>
                    <th>ログレベル</th>
                    <td>{data.level}</td>
                  </tr>
                  <tr>
                    <th>更新者名</th>
                    <td>{data.user_name}</td>
                  </tr>
                  <tr>
                    <th>メッセージ</th>
                    <td>{data.message}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>Action</th>
                    <td>{data.action}</td>
                  </tr>
                  <tr>
                    <th>パラメータ 1</th>
                    <td>{data.param1}</td>
                  </tr>
                  <tr>
                    <th>操作IPアドレス</th>
                    <td>{data.remote_ip}</td>
                  </tr>
                  <tr>
                    <th>操作時URL</th>
                    <td>{data.uri}</td>
                  </tr>
                  <tr>
                    <th>HTTP_USER_AGENT</th>
                    <td>{data.user_agent}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}