import React, { useState, useEffect } from 'react';
import * as _form from '../../helper/form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ZshDateTimePicker from '../Form/ZshDateTimePicker';

export type DataTableFiltersOptionProps = {
  text: string;
  value: string | number;
}

export type DataTableFiltersProps = {
  key?: string;
  title: string;
  type?: "select" | "date" | "datetime" | "inlineSelect" | undefined;
  dataKey?: string;
  options?: DataTableFiltersOptionProps[];
  emptyTitle?: string;
  loading?: boolean;
  valueType?: 'string' | 'number' | undefined;
  keys?: DataTableFiltersProps[];
  infoElement?: () => JSX.Element;
  valueElement?: (queries: any, filter: DataTableFiltersProps) => JSX.Element
}

type DataTableFilterProps = {
  dataTableParams: DataTableFiltersProps[];
  queries: any;
  handleChangeFilters: (values: { [key: string]: any }) => void;
  setSearch: (key: any, value: any) => void;
}

export default function DataTableFilters({
  dataTableParams,
  queries,
  handleChangeFilters,
  setSearch,
}: DataTableFilterProps) {

  const [dialog, setDialog] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
  const [dialogFilter, setDialogFilter] = useState<DataTableFiltersProps>({} as DataTableFiltersProps);
  const [dialogFilterValue, setDialogFilterValue] = useState<{[key: string]: any}>({});
  const [dialogFilterPickerValue, setDialogFilterPickerValue] = useState<{[key: string]: Date | null}>({});
  const [isInit, setIsInit] = useState<boolean>(true);

  const getValues = (): { [key: string]: any } => {
    let values: { [key: string]: any } = {};
    if (
      typeof dialogFilter.key !== "undefined" &&
      dialogFilter.key !== "" &&
      typeof dialogFilterValue[dialogFilter.key] !== "undefined"
    ) {
      values[dialogFilter.key] = dialogFilterValue[dialogFilter.key];
    }
    if (typeof dialogFilter.keys !== "undefined") {
      dialogFilter.keys.forEach((filter: DataTableFiltersProps) => {
        if (typeof filter.key !== "undefined" && typeof dialogFilterValue[filter.key] !== "undefined") {
          values[filter.key] = dialogFilterValue[filter.key];
        }
      });
    }
    if (Object.keys(filterValues).length > 0) {
      Object.keys(filterValues).forEach((key: string) => {
        if (typeof values[key] === "undefined") {
          values[key] = filterValues[key];  
        }
      })
    }
    return values;
  }

  const handleChangeDialog = (): void => {
    handleChangeFilters(getValues());
    setDialog(false);
  }

  const handleChangeDialogSelect = (event: any, key: string | undefined): void => {
    if (key !== undefined) {
      setDialogFilterValue({
        ...dialogFilterValue,
        [key]: event.target.value,
      });
    }
  }

  const handleDatePickerChange = (date: Date | null, key: string | undefined): void => {
    const dateStringValue: string = date === null ? "" : _form.formatDate(date);
    if (key !== undefined) {
      setDialogFilterValue({
        ...dialogFilterValue,
        [key]: dateStringValue,
      });
      setDialogFilterPickerValue({
        ...dialogFilterPickerValue,
        [key]: date,
      });
    }
  }

  const handleCloseDialog = (): void => {
    setDialog(false);
  }

  const handleClickFilter = (filter: DataTableFiltersProps): void => {
    let filters: DataTableFiltersProps[] = [];
    let filterValues: any = {};
    let filterPickerValues: any = {};
    if (filter.keys === undefined) {
      filters.push(filter);
    } else {
      filter.keys.forEach((fil: DataTableFiltersProps) => {
        filters.push(fil);
      });
    }
    filters.forEach((filter: DataTableFiltersProps) => {
      let thisValue: any = '';
      let thisPickerValue: any = null;
      if (filter.key !== undefined) {
        if (typeof queries[filter.key] !== 'undefined') {
          thisValue = queries[filter.key]
        }
        filterValues[filter.key] = thisValue
        // set picker values
        if (filter.type === 'date' || filter.type === 'datetime') {
          if (typeof queries[filter.key] !== 'undefined' && queries[filter.key] !== '') {
            thisPickerValue = new Date(queries[filter.key]);
          }
          filterPickerValues[filter.key] = thisPickerValue;
        }
      }
    });
    setDialogFilterValue(filterValues);
    setDialogFilterPickerValue(filterPickerValues);
    setDialogFilter(filter);
    setDialog(true);
  }

  const clearValues = (filter: DataTableFiltersProps): void => {
    let values: { [key: string]: any } = getValues();
    let tmpDialogFilterValue = Object.assign({}, dialogFilterValue);
    let tmpDialogFilterPickerValue = Object.assign({}, dialogFilterPickerValue);
    if (filter.key !== undefined) {
      if (typeof values[filter.key] !== 'undefined') {
        values[filter.key] = '';
      }
      if (typeof tmpDialogFilterValue[filter.key] !== 'undefined') {
        tmpDialogFilterValue[filter.key] = '';
      }
      if (typeof tmpDialogFilterPickerValue[filter.key] !== 'undefined') {
        tmpDialogFilterPickerValue[filter.key] = null;
      }
    }
    if (filter.keys !== undefined) {
      filter.keys.forEach((fil: DataTableFiltersProps) => {
        if (fil.key !== undefined) {
          if (typeof values[fil.key] !== 'undefined') {
            values[fil.key] = '';
          }
          if (typeof tmpDialogFilterValue[fil.key] !== 'undefined') {
            tmpDialogFilterValue[fil.key] = '';
          }
          if (typeof tmpDialogFilterPickerValue[fil.key] !== 'undefined') {
            tmpDialogFilterPickerValue[fil.key] = null;
          }
        }
      });
    }
    setFilterValues(values);
    setDialogFilterValue(tmpDialogFilterValue);
    setDialogFilterPickerValue(tmpDialogFilterPickerValue);
    handleChangeFilters(values);
  }

  const handleChangeInlineSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string | undefined): void => {
    let values: any = getValues();
    if (key !== undefined) {
      values[key] = event.target.value;
      setFilterValues({
        ...filterValues,
        [key]: event.target.value,
      });
    }
    handleChangeFilters(values);
  }

  const inlineSelect = (filter: DataTableFiltersProps): JSX.Element => {
    const emptyTitle: string = dialogFilter.emptyTitle ? dialogFilter.emptyTitle : '全て';
    const key: string = filter.key === undefined ? '' : filter.key;
    return (
      <div
        style={
          {
            paddingLeft: '3px'
          }
        }
      >
        <select
          value={filterValues[key]}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleChangeInlineSelect(event, filter.key)}
          style={
            {
              padding: '2px',
              borderColor: '#e0e0e0',
            }
          }
        >
          <option value="">{emptyTitle}</option>
          {
            filter.options !== undefined &&
            filter.options.map((option: any) => (
              <option
                value={option.value}
                key={option.value}
              >
                {option.text}
              </option>
            ))
          }
        </select>
      </div>
    );
  }

  const filterValue = (filter: DataTableFiltersProps): JSX.Element => {
    let value: any = '';
    let thisFilterValue: string | number = '';
    let paramType: string = 'info';
    const thisDataTableParam: DataTableFiltersProps | undefined = dataTableParams.find(param => param.title === filter.title);
    if (filter.key !== undefined) {
      paramType = 'filter';
    }
    if (filter.keys !== undefined) {
      paramType = 'filter';
    }
    if (filter.type === 'inlineSelect') {
      paramType = 'inlineSelect';
    }
    // set value
    if (filter.valueElement === undefined) {
      if (filter.key !== undefined) {
        if (queries[filter.key] !== undefined) {
          if (filter.valueType !== undefined && filter.valueType === 'number') {
            thisFilterValue = Number(queries[filter.key]);
          } else {
            thisFilterValue = queries[filter.key];
          }
        }
      }
      switch (filter.type) {
        case 'select':
        case 'inlineSelect':
          value = '全て';
          if (typeof filter.options !== 'undefined') {
            const selectValue: DataTableFiltersOptionProps | undefined = filter.options.find(option => option.value === thisFilterValue);
            if (selectValue !== undefined) {
              value = selectValue.text;
            }
          }
          break;

        default:
          value = '未設定';
          if (thisFilterValue !== '') {
            value = thisFilterValue;
          }
          break;
      }
    } else {
      value = filter.valueElement(queries, filter);
    }
    return (
      <div>
        {
          paramType === 'filter' &&
          <Button
            onClick={() => handleClickFilter(filter)}
          >
            {value}
          </Button>
        }
        {
          paramType === 'info' && thisDataTableParam !== undefined &&
          <div className="datatable-param-info">
            {
              filter.infoElement !== undefined &&
              <div>
                {
                  thisDataTableParam.infoElement !== undefined &&
                  <div>
                    {thisDataTableParam.infoElement()}
                  </div>
                }
              </div>
            }
          </div>
        }
        {
          paramType === 'inlineSelect' &&
          <>
            {inlineSelect(filter)}
          </>
        }
      </div>
    );
  }

  const filterElement = (filter: DataTableFiltersProps): JSX.Element => {
    let showClear: boolean = false;
    if (filter.type === 'date' || filter.type === 'datetime') {
      if (filter.keys !== undefined) {
        filter.keys.forEach((fil: DataTableFiltersProps) => {
          if (fil.key !== undefined && typeof queries[fil.key] !== 'undefined') {
            if (queries[fil.key] !== '') {
              showClear = true;
            }
          }
        });
      }
    }
    return (
      <>
        <div className="datatable-param-title">{filter.title}</div>
        <div className="datatable-param-value">
          {filterValue(filter)}
        </div>
        {
          showClear &&
          <div>
            <ClearIcon
              style={
                {
                  fontSize: '14px',
                  marginTop: '4px',
                  cursor: 'pointer'
                }
              }
              onClick={() => clearValues(filter)}
            />
          </div>
        }
      </>
    );
  }

  const dialogContent = (): JSX.Element => {
    const emptyTitle: string = dialogFilter.emptyTitle ? dialogFilter.emptyTitle : '全て';
    let filters: DataTableFiltersProps[] = [];
    if (dialogFilter.keys === undefined) {
      filters.push(dialogFilter);
    } else {
      dialogFilter.keys.forEach((fil: DataTableFiltersProps) => {
        filters.push(fil);
      });
    }
    return (
      <div>
        {
          filters.map((filter: DataTableFiltersProps, index: number) => (
            <div
              key={`dialog-input-${index}`}
              className="datatable-params-dialog-input-wr"
            >
              {
                filter.type === 'select' && filter.key !== undefined &&
                <FormControl>
                  <Select
                    native
                    value={dialogFilterValue[filter.key]}
                    onChange={(event) => handleChangeDialogSelect(event, filter.key)}
                    inputProps={{
                      name: filter.key,
                      id: filter.key,
                    }}
                  >
                    <option value="">{emptyTitle}</option>
                    {
                      filter.options !== undefined &&
                      filter.options.map((option: any) => (
                        <option value={option.value} key={option.value}>{option.text}</option>
                      ))
                    }
                  </Select>
                </FormControl>
              }
              {
                filter.type === 'date' && filter.key !== undefined &&
                <div className="form-input-group">
                  <div className="datatable-params-dialog-input-title">
                    {filter.title}
                  </div>
                  <ZshDateTimePicker
                    name="purchase_date"
                    format="YYYY/MM/DD"
                    views={['date']}
                    value={dialogFilterPickerValue[filter.key]}
                    handleChange={(date: Date | null) => handleDatePickerChange(date, filter.key)}
                    type="date"
                  />
                </div>
              }
            </div>
          ))
        }
      </div>
    );
  }

  useEffect(() => {
    if (isInit) {
      // format filter
      dataTableParams.forEach((filter: DataTableFiltersProps) => {
        // set url values
        if (filter.key !== undefined) {
          if (queries[filter.key] !== undefined && queries[filter.key] !== '') {
            setSearch(filter.key, queries[filter.key]);
          }
        }
        // set empty text
        if (filter.emptyTitle === undefined) {
          filter.emptyTitle = '全て';
        }
      });
      setIsInit(false);
    }
  }, [dataTableParams, isInit, queries, setSearch]);

  // set values
  useEffect(() => {
    let tmpValues: any = {};
    // format filter
    dataTableParams.forEach((filter: DataTableFiltersProps) => {
      // set url values
      if (filter.key !== undefined) {
        if (queries[filter.key] !== undefined && queries[filter.key] !== '') {
          tmpValues[filter.key] = queries[filter.key];
        }
      }
      if (filter.keys !== undefined) {
        filter.keys.forEach((fil: DataTableFiltersProps) => {
          if (fil.key !== undefined) {
            if (queries[fil.key] !== undefined && queries[fil.key] !== '') {
              tmpValues[fil.key] = queries[fil.key];
            }
          }
        });
      }
    });
    setFilterValues(tmpValues);
  }, [dataTableParams, queries]);

  return (
    <div className="datatable-params">
      {
        dataTableParams.length > 0 &&
        <div className="datatable-params-wr">
          {
            dataTableParams.map((filter: DataTableFiltersProps, index: number) => (
              <div
                className="datatable-param"
                key={`data-params-${index}`}
              >
                {filterElement(filter)}
              </div>
            ))
          }
        </div>
      }
      <Dialog
        open={dialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle id="alert-dialog-title">
          {
            dialogFilter.title !== undefined &&
            <div>
              {dialogFilter.title}
            </div>
          }
        </DialogTitle>
        <DialogContent>
          {dialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default">
            閉じる
          </Button>
          <Button
            color="primary"
            onClick={(event: any) => handleChangeDialog()}
            autoFocus>
            検索
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}