import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import * as _usr_const from '../../config/usr-constant';
import * as _debug from '../../helper/debug';
import axios from 'axios';

type ExpensesClientsProps = {
  open: boolean;
  setShowClients: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectClient: (client: string) => void;
}

export default function ExpensesClients({
  open,
  setShowClients,
  handleSelectClient
}: ExpensesClientsProps) {

  const [clients, setClients] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');

  const handleClose = (): void => {
    setShowClients(false);
  }

  const handleAgree = (client: string): void => {
    handleSelectClient(client);
  }

  const handleChangeSearch = (event: any): void => {
    setSearch(event.target.value)
  }

  const getClients = useCallback(() => {
    axios
    .get(
      _usr_const.ApiUrl + 'expenses/expense-clients',
      {
        params: {
          s: search
        }
      }
    )
    .then((response) => {    
      if (typeof response.data !== 'undefined') {
        if (response.data === null) {
          setClients([]);
        } else {
          setClients(response.data);
        }
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
    });
  }, [search]);

  useEffect(() => {
    if (open) {
      getClients();
    } else {
      setSearch('');
    }
  }, [open, search, getClients, setSearch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">取引先名一覧</DialogTitle>
      <DialogContent>
        <div>
          <TextField
            placeholder="取引先名"
            fullWidth
            value={search}
            onChange={handleChangeSearch}
            autoFocus
            focused
          />
        </div>
        <List
          component="nav"
          aria-label="expense clients"
          style={{
            minWidth: '200px',
          }}
        >
          {
            clients.map((client: string, index: number) => (
              <ListItem
                button
                key={index}
                onClick={() => handleAgree(client)}
              >
                <ListItemText primary={client} />
              </ListItem>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}