import * as _usr_const from '../../../config/usr-constant';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

type getUserListProps = {
  unmounted: boolean,
  setUserList: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>,
  setUserListError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getUserList({
  unmounted,
  setUserList,
  setUserListError,
  source
}: getUserListProps) {
  // get data
  await axios
  .get(
    _usr_const.ApiUrl + 'users/user-list', {
      cancelToken: source.current.token
    }
  )
  .then((response) => {    
    if (typeof response.data !== 'undefined') {
        let types: any = {};
        for (const ismTypes of response.data) {
          types[ismTypes['ID']] = ismTypes['full_name'];
        }
        if (!unmounted) {
          setUserList(types);
        }
    }
  })
  .catch((error) => {
    _debug.debugAxiosError(error);
    if (!unmounted) { 
      setUserListError(true);
    }
  })
  .finally(() => {
    return Promise.resolve(1);
  });
}

type getExpenseItemListProps = {
  unmounted: boolean,
  setExpenseItemList: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>,
  setExpenseItemListError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getExpenseItemList({
  unmounted,
  setExpenseItemList,
  setExpenseItemListError,
  source
}: getExpenseItemListProps) {
  // get data
  await axios
  .get(
    _usr_const.ApiUrl + 'expense-items/list', {
      cancelToken: source.current.token
    }
  )
  .then((response) => {    
    if (typeof response.data !== 'undefined') {
        let types: any = {};
        for (const ismTypes of response.data) {
          types[ismTypes['ID']] = ismTypes['name'];
        }
        if (!unmounted) {
          setExpenseItemList(types);
        }
    }
  })
  .catch((error) => {
    _debug.debugAxiosError(error);
    if (!unmounted) { 
      setExpenseItemListError(true);
    }
  })
  .finally(() => {
    return Promise.resolve(1);
  });
}