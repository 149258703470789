import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
// import jaLocale from "date-fns/locale/ja";
// import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/ja";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type ZshDateTimePickerProps = {
  label?: string;
  value: any;
  name: string;
  handleChange: (date: Date | null) => void
  format?: 'YYYY/MM/DD HH:mm' | 'YYYY/MM/DD HH' | 'YYYY/MM/DD' | undefined;
  views?: ("date" | "hours" | "year" | "month" | "minutes")[];
  required?: boolean;
  validateErrors?: { [key: string]: string[] };
  type?: 'date' | 'datetime';
}

export default function ZshDateTimePicker({
  label = '',
  value,
  name,
  handleChange,
  format = 'YYYY/MM/DD HH:mm',
  views = ['date', 'hours'],
  required = false,
  validateErrors,
  type = 'datetime'
}: ZshDateTimePickerProps) {

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const isRequired: boolean = required === undefined ? false : required;

  const handleChangeDate = (date: MaterialUiPickersDate | null): void => {
    if (date === null) {
      handleChange(null)
    } else {
      handleChange(date.toDate());
    }
  }

  useEffect(() => {
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <FormControl
      error={errorMessages.length > 0}
      className="zsh-datetime-field"
      required={isRequired}
    >
      <div className="zsh-datetime-wr">
        {
          type === 'datetime' &&
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateTimePicker
              label={label}
              value={value}
              onChange={(date: any) => handleChangeDate(date)}
              format={format}
              views={views}
              required={isRequired}
            />
          </MuiPickersUtilsProvider>
        }
        {
          type === 'date' &&
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker
              label={label}
              value={value}
              onChange={(date: any) => handleChangeDate(date)}
              format={format}
              required={isRequired}
            />
          </MuiPickersUtilsProvider>
        }
        <Button
        size="small"
        variant="outlined"
        className="zsh-datetime-clear-btn"
        onClick={() => handleChangeDate(null)}
        >
          クリア
        </Button>
      </div>
      {
        errorMessages.length > 0 &&
        <span className="zsh-datetime-field-er-wr">
          {
            errorMessages.map((msg: string, index: number) => (
              <span key={name + '-erSeMsg-' + index} className="zsh-datetime-field-er-msg">
                <FormHelperText>{msg}</FormHelperText><br />
              </span>
            ))
          }
        </span>
      }
    </FormControl>
  )
}