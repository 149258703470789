import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import "react-redux";
import reduxThunk from 'redux-thunk'
import FlashMessage from './reducers/FlashMessage';
import AuthInformations from './reducers/AuthInformations';
import AuthRoles from './reducers/AuthRoles';
import Dialog from './reducers/Dialog';
import OverlayProgress from './reducers/OverlayProgress';

const reducers = {
  FlashMessage,
  AuthInformations,
  AuthRoles,
  Dialog,
  OverlayProgress,
}

const reducer = combineReducers(reducers);
const store = configureStore({ reducer, middleware: [reduxThunk] });

type StoreState = {
  [key in keyof typeof reducers]: NonNullable<Parameters<typeof reducers[key]>[0]>;
};

declare module "react-redux" {
  interface DefaultRootState extends StoreState {}
}

export default store;