import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

type AjaxSelectProps = {
  name: string;
  label?: string;
  menuItems: any;
  loading?: boolean;
  formData: any;
  handleChange: any;
  getError?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  validateErrors?: {[key: string]: string[]};
  style?: React.CSSProperties;
}

export default function AjaxSelect({
  name,
  label,
  menuItems,
  loading,
  formData,
  handleChange,
  required = false,
  fullWidth = false,
  validateErrors,
  style = {}
}: AjaxSelectProps) {

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  let setMenuItems: any = menuItems;
  if (typeof menuItems !== 'object') {
    setMenuItems = {};
  }

  useEffect(() => {    
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <FormControl
    error={errorMessages.length > 0}
    required={required}
    className="zsh-select-root"
    >
      {
        label !== undefined &&
        <InputLabel htmlFor={name}>{label}</InputLabel>
      }
      <Select
        value={formData[name]}
        name={name}
        onChange={handleChange}
        className="form-input zsh-select"
        inputProps={{
          name: name,
        }}
        required={required}
        fullWidth={fullWidth}
        style={style}
      >
        <MenuItem value=""></MenuItem>
        {
          Object.keys(setMenuItems).map((val: any, index: number) => (
            <MenuItem value={val} key={name + '-' + index}>{setMenuItems[val]}</MenuItem>
          ))
        }
      </Select>
      {
        loading === true &&
        <LinearProgress />
      }
      {
        errorMessages.length > 0 &&
        <span className="zsh-select-field-er-wr">
        {
          errorMessages.map((msg: string, index: number) => (
            <span key={name + '-erSeMsg-' + index} className="zsh-select-field-er-msg">
              <FormHelperText>{msg}</FormHelperText><br/>
            </span>
          ))
        }
        </span>
      }
    </FormControl>
  )
}