import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import DataTable, {
  DataTableRowsProps,
  DataTableSearchInputParamsProps,
  DataTableActionsProps,
  DataTableActionsFuncProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import * as _data_actions from '../../helper/dataActions';
import { ExpenseItem } from '../../types/model';

export default function ExpenseItemsIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const clickCell = (params: DataTableHandleClickCellProps<ExpenseItem>): void => {
    history.push(location.pathname + '/' + params.record.ID + location.search);
  }

  const handleToAdd = (): void => {
    history.push('/expense-items/add');
  }

  const confirmDelete = (params: DataTableActionsFuncProps<ExpenseItem>): void => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: DataTableActionsFuncProps<ExpenseItem>): void => {
    _data_actions.deleteData({
      deleteUrl: 'expense-items/delete',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const rows: DataTableRowsProps<ExpenseItem>[] = [
    {
      label: '勘定科目',
      value: 'account',
      sortField: 'account',
      align: 'left'
    },
    {
      label: '相手科目',
      value: 'subject',
      sortField: 'subject',
      align: 'left',
    },
    {
      label: '備考',
      value: 'remarks',
      align: 'left',
    },
    {
      label: '更新日時',
      value: 'UpdatedAt',
      filter: 'YMDHm',
      sortField: 'updated_at',
      align: 'left',
    },
  ];

  const searchInputParams: DataTableSearchInputParamsProps[] = [
    {
      title: '勘定科目',
      text: '部分一致',
    },
    {
      title: '相手科目',
      text: '部分一致',
    },
  ];

  const actions: DataTableActionsProps<ExpenseItem>[] = [
    {
      title: '新規追加',
      func: handleToAdd,
      color: 'primary',
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
    },
  ];

  return (
    <div id="expense-items" className="content-1">
      <DataTable<ExpenseItem>
        rows={rows}
        checkbox={true}
        jsonPath='expense-items'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchInputParams={searchInputParams}
        actions={actions}
      />
    </div>
  )
}