const initialState = {
  type: '',
  title: '',
  dialogType: '',
  message: '',
  funcAgree: {},
  closeText: '',
  agreeText: '',
  open: false,
}
export default function Dialog(state = initialState, action: any): any {

  const { params } = action;

  let title,
  message,
  dialogType,
  funcAgree,
  closeText,
  agreeText;

  if (params !== undefined) {
    title = params.title? params.title : '';
    message = params.message !== undefined? params.message : state.message;
    dialogType = params.dialogType !== undefined? params.dialogType : state.dialogType;
    funcAgree = params.funcAgree !== undefined? params.funcAgree : state.funcAgree;
    closeText = params.closeText !== undefined? params.closeText : state.closeText;
    agreeText = params.agreeText !== undefined? params.agreeText : state.agreeText;
    if (dialogType !== '') {
      switch (dialogType) {
        case 'delete':
          message = '選択した項目を削除します。¥n（関連するデータも全て削除されます）¥n¥nこの操作は元に戻せません。よろしいですか？';
          closeText = 'キャンセル';
          agreeText = '削除する';
          break;
        default:
          break;
      }
    }
  }

  switch (action.type) {
    case 'OPEN_DIALOG':
      return Object.assign({}, state, {
        type: action.type,
        title,
        message,
        funcAgree: funcAgree,
        closeText,
        agreeText,
        open: true,
      });
    case 'CLOSE_DIALOG':
      return Object.assign({}, state, {
        type: action.type,
        open: false,
      });
    default:
        return state;
  }
}