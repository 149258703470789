export function formatCssStyleToReactStyle(style: string): {[key: string]: React.Key} {
  if (style === '' || style === null) {
    return {};
  }
  let results: {[key: string]: string | number} = {};
  const styleSplit: string[] = style.split(';');
  styleSplit.forEach((prop: string) => {
    if (prop.length > 0) {
      const propSplit: any = prop.split(':');
      let styleName: string = propSplit[0];
      let styleValue: string = propSplit[1];
      const styleNameIndex: number = propSplit[0].indexOf('-');
      if (styleNameIndex !== -1) {
        let formatStyleName = styleName.slice(styleNameIndex);
        formatStyleName = formatStyleName.replace('-', '');
        formatStyleName = formatStyleName.slice(0, 1).toUpperCase() + formatStyleName.slice(1);      
        styleName = styleName.slice(0, styleNameIndex) + formatStyleName;
      }
      styleName = styleName.replace(/\s+/g, '');
      styleValue = styleValue.replace(/\s+/g, '');
      results[styleName] = styleValue; 
    }
  });
  return results;
}