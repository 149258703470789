import React, { useState, useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { zshDialog } from '../atoms/ZshDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ZshDialog() {

  const zshDialogValue = useRecoilValue(zshDialog);
  const resetZshDialog = useResetRecoilState(zshDialog);

  const [message, setMessage] = useState<any>('');
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [closeText, setCloseText] = useState<string>('');
  const [agreeText, setAgreeText] = useState<string>('');
  const [dialogClass, setDialogClass] = useState<string>('');

  const handleClose = (): void => {
    resetZshDialog();
  };

  const handleAgree = (): void => {
    if (zshDialogValue.funcAgree !== undefined && typeof zshDialogValue.funcAgree === 'function') {
      zshDialogValue.funcAgree();
    }
    handleClose();
  }

  useEffect(() => {
    let tmpMessage: any = '';
    if (zshDialogValue.message !== undefined) {
      if (zshDialogValue.message.indexOf('¥n') !== -1) {
        tmpMessage = zshDialogValue.message.split('¥n').map((line: string, index: number) => (
          <span key={index}>
            {line}<br />
          </span>
        ));
      } else {
        tmpMessage = zshDialogValue.message;
      }
      setOpen(zshDialogValue.open);
      setTitle(zshDialogValue.title === undefined ? '' : zshDialogValue.title);
      setCloseText(zshDialogValue.closeText === undefined ? '' : zshDialogValue.closeText);
      setAgreeText(zshDialogValue.agreeText === undefined ? '' : zshDialogValue.agreeText);
      setDialogClass(zshDialogValue.open ? 'open-zsh-dialog' : '');
    }
    setMessage(tmpMessage);
  }, [zshDialogValue]);

  return (
    <div className={dialogClass}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="zsh-dialog"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <React.Fragment>
              {message}
            </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {closeText}
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {agreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}