import { DataTableRowsProps } from '../components/View/DataTable';

export const AppUrl = process.env.REACT_APP_URL;
export const ApiUrl = typeof process.env.REACT_APP_API_URL === 'undefined' ? '' : process.env.REACT_APP_API_URL;
export const UserRoleAllow = {};
export const UserMenus = [];
export const setloginRedirect = '';

export const customerRows: DataTableRowsProps[] = [
  {
    label: 'No',
    value: 'primary_number',
    sortField: 'Customers.primary_number',
    align: 'left',
  },
  {
    label: '顧客番号',
    value: 'customer_number',
    sortField: 'Customers.customer_number',
    align: 'left'
  },
  {
    label: '氏名',
    value: 'full_name',
    align: 'left',
  },
  {
    label: 'ステータス',
    value: 'status',
    align: 'left',
  },
  {
    label: '登録日時',
    value: 'created',
    filter: 'YMDHm',
    sortField: 'Customers.created',
    align: 'left',
  },
  {
    label: '更新日時',
    value: 'modified',
    filter: 'YMDHm',
    sortField: 'Customers.modified',
    align: 'left',
  },
];

export const userStatuses: {[key: number]: string} = {
  10: '利用中',
  90: '停止',
}

export const userAuthorities: {[key: number]: string} = {
  10: '利用者',
  90: '管理者',
  100: 'システム管理者',
}

export const expenseType: {[key: string]: string} = {
  unpaid: '未精算',
  scheduled: '精算予定',
  paided: '精算済み',
}