const initialState = {
  type: '',
  message: '',
  level: '',
}

export default function FlashMessage(state = initialState, action: any): any {
  const { message } = action;
  switch (action.type) {
    case 'SUCCESS_FLASH':
      return Object.assign({}, state, {
        type: action.type,
        level: 'success',
        message,
      });
    case 'WARNIG_FLASH':
      return Object.assign({}, state, {
        type: action.type,
        level: 'warnig',
        message,
      });
    case 'ERROR_FLASH':
      return Object.assign({}, state, {
        type: action.type,
        level: 'error',
        message,
      });
    case 'INFO_FLASH':
      return Object.assign({}, state, {
        type: action.type,
        level: 'info',
        message,
      });
    case 'DESTROY_FLASH':
      return Object.assign({}, state, {
        type: '',
        level: '',
        message,
      });
    default:
        return state;
  }
}