import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: 400,
      position: 'relative',
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    info: {
      position: 'absolute',
      left: 0,
      top: 50,
      width: '100%',
      backgroundColor: '#FFF',
      padding: '10px',
      zIndex: 1000,
      border: '1px solid #EEE',
    },
    infoWr: {
      display: 'block',
      paddingBottom: '5px',
      '&:last-child': {
        paddingBottom: 0,
      }
    },
    infoTitle: {
      display: 'inline-block',
      color: '#337ab7',
      fontWeight: 'bold',
    },
    infoText: {
      display: 'inline-block',
    }
  })
);

export type SearchInputInfoParamProps = {
  title: string;
  text: string;
}

type SearchInputProps = {
  infoParams: SearchInputInfoParamProps[];
  searchValues: { [key: string]: any };
  setSearch: (key: any, value: any) => void;
  onSearchSubmit: (event: any) => void;
}

export default function SearchInput({
  infoParams,
  searchValues,
  setSearch,
  onSearchSubmit
}: SearchInputProps) {

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [searchAction, setSearchAction] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);

  const handleSerachInput = (event?: any): void => {
    if (inputSearch === '') {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }

  const handleChangeSerachInput = (event: any): void => {
    setSearch('search', event.target.value);
    setInputSearch(event.target.value);
    if (event.target.value === '') {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }

  const handleBlur = (): void => {
    setShowInfo(false);
  }

  const handleSerach = (event: any): void => {
    if (inputSearch === '') {
      setSearchAction(false);
    } else {
      setSearchAction(true);
      onSearchSubmit(event);
    }
  }

  const handleKeyPress = (event: any): void => {
    if (event.keyCode === 13) {
      handleSerach(event);
    }
  }

  useEffect(() => {
    if (isInit) {
      if (searchValues.search !== undefined && searchValues.search !== '') {
        setSearchAction(true);
        setInputSearch(searchValues.search);
      }
      setIsInit(false);
    }
  }, [searchValues, isInit]);

  const classes = useStyles();

  const iconColor: 'primary' | 'default' = searchAction ? "primary" : "default";

  const infoClassName: string = 'search-input-info';

  return (
    <div className="search-input">
      <Paper className={classes.paper} elevation={1}>
        <InputBase
          className={classes.input}
          placeholder="Search"
          onFocus={(event) => handleSerachInput(event)}
          onChange={(event) => handleChangeSerachInput(event)}
          onBlur={handleBlur}
          onKeyDown={(event) => handleKeyPress(event)}
          value={inputSearch}
          name="search"
        />
        <IconButton
          className={classes.iconButton}
          onClick={(event: any) => handleSerach(event)}
          aria-label="Search"
          color={iconColor}
        >
          <SearchIcon />
        </IconButton>
        {
          showInfo &&
          <div className={classes.info + ' ' + infoClassName}>
            {
              infoParams.map((param: SearchInputInfoParamProps, index: number) => (
                <div key={index} className={classes.infoWr}>
                  <div className={classes.infoTitle}>{param.title}：</div>
                  <div className={classes.infoText}>{param.text}</div>
                </div>
              ))
            }
          </div>
        }
      </Paper>
    </div>
  )
}
