import * as _usr_const from '../../../config/usr-constant';
import * as _form from '../../../helper/form';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

/**
 * getUserFormParams
 * 
 * @param obj 
 */
type getUserFormParamsProps = {
  unmounted: any;
  setLoadings: any;
  setAuthorities: React.Dispatch<React.SetStateAction<{}>>;
  setStatuses: React.Dispatch<React.SetStateAction<{}>>;
  setStatusesError: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export async function getUserFormParams({
  unmounted,
  setLoadings,
  setAuthorities,
  setStatuses,
  setStatusesError,
  setLoading
}: getUserFormParamsProps) {
  let authorities: {[key: number]: string} = {};
  Object.keys(_usr_const.userAuthorities).forEach((auk: string) => {
    const numAuK: number = Number(auk);
    if (numAuK < 100) {
      authorities[numAuK] = _usr_const.userAuthorities[numAuK];
    }
  });
  setAuthorities(authorities);
  setStatuses(_usr_const.userStatuses);
  setLoadings({
    authorities: false,
    statuses: false,
  });
  setLoading(false);
  return Promise.resolve(1)
  // get data
  // await axios
  //   .get(
  //     _usr_const.ApiUrl + 'users/form-params'
  //   )
  //   .then((response) => {
  //     if (typeof response.data !== 'undefined') {
  //       if (!unmounted.current) {
  //         setAuthorities(response.data.authorities);
  //         setStatuses(response.data.user_status);
  //         setLoadings({
  //           authorities: false,
  //           statuses: false,
  //         });
  //         setLoading(false);
  //       }
  //     }
  //   })
  //   .catch((error) => {
  //     _debug.debugAxiosError(error);
  //     if (!unmounted.current) {
  //       setStatusesError(true);
  //     }
  //   })
  //   .finally(() => {
  //     return Promise.resolve(1)
  //   });
}

/**
 * getEditParams
 * 
 * @param obj 
 */
type getEditParamsProps = {
  unmounted: any;
  actionName: string;
  id: string;
  formData: any;
  setFormData: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setdataMounted: React.Dispatch<React.SetStateAction<boolean>>;
}
export async function getEditParams({
  unmounted,
  actionName,
  id,
  formData,
  setFormData,
  setLoading,
  setdataMounted
}: getEditParamsProps) {
  // set data
  setLoading(true);
  await axios
    .get(_usr_const.ApiUrl + actionName + id)
    .then((response: any) => {
      if (!unmounted.current) {
        // set form data
        const tmpFormData: any = _form.setFormData(formData, response.data);
        setFormData(tmpFormData);
        setdataMounted(true);
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
    })
    .finally(() => {
      if (!unmounted.current) {
        setLoading(false);
      }
      return Promise.resolve(1)
    });
}