import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import './scss/Base.scss';
import './scss/Style.scss';
import Parent from './dashboard/Parent';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios'
import { RecoilRoot } from 'recoil';

// set default headers
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
}

// set BearerToken
const expenses_api_token = localStorage.getItem('expenses_api_token');
const BearerToken:string = 'Bearer ' + expenses_api_token;
if (expenses_api_token !== null) {
  axios.defaults.headers.common['Authorization'] = BearerToken;
}

ReactDOM.render(
  <RecoilRoot>
    <Provider store={store}>
      <Router>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Parent />
        </MuiPickersUtilsProvider>
      </Router>
    </Provider>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
