import React from 'react';

type MultiRowTextProps = {
  text: string;
}

export default function MultiRowText({
  text
}:  MultiRowTextProps) {
  const nl2br = (str: string): any[] => {
    const regex = /([\n])/g;
    return str.split(regex).map((line: any, i: number) => {
      return line.match(regex) ? <br key={i} /> : line;
    });
  }
  return (
    <span className="multi-row">{nl2br(text)}</span>
  )
}