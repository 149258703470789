import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import Paper from '@material-ui/core/Paper';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';

export type ExpenseItemFormDataProps = {
  account: string;
  subject: string;
  remarks: string;
}

export default function ExpenseItemsAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName: string = 'expense-items/add';
  const backActionName: string = '/expense-items';

  const [formData, setFormData] = useState<ExpenseItemFormDataProps>({
    account: '',
    subject: '',
    remarks: ''
  });
  const [validateErrors, setValidateErrors] = useState<{ [key: string]: string[] }>({});

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName,
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="expense-items" className="content-1">
      <form onSubmit={handleSubmit}>
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in form-content">
              <table className="form-table">
                <tbody>
                  <tr>
                    <th className="required-th">勘定科目</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.account}
                          name="account"
                          handleChange={handleChange}
                          required
                          validateErrors={validateErrors}
                          fullWidth
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="required-th">相手科目</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.subject}
                          name="subject"
                          handleChange={handleChange}
                          required
                          validateErrors={validateErrors}
                          fullWidth
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.remarks}
                          name="remarks"
                          handleChange={handleChange}
                          validateErrors={validateErrors}
                          fullWidth
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
        </div>
      </form>
    </div>
  )
}