import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import * as _usersFormFunc from './func/UsersForm';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshTextField from '../../components/Form/ZshTextField';
import axios from 'axios';

type formDataProps = {
  username: string;
  password: string;
  authority: string;
  status: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
}

function UsersEdit({ history, match }: RouteComponentProps<{ id: string; }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName = 'users/';
  const backActionName = '/users';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dataMounted, setdataMounted] = useState(false);
  const [formData, setFormData] = useState<formDataProps>({
    username: '',
    password: '',
    authority: '',
    status: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
  });
  const [validateErrors, setValidateErrors] = useState({});
  const [authorities, setAuthorities] = useState({});
  const [statuses, setStatuses] = useState({});
  const [statusesError, setStatusesError] = useState(false);
  const [loadings, setLoadings] = useState({
    authorities: true,
    statuses: true
  });

  const handleChange = (event: any) => {
    const inputName = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    _form.handleSubmit({
      action: actionName + match.params.id,
      method: 'put',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel customer form statuses get');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      // set params
      (async () => {
        await _usersFormFunc.getUserFormParams({
          unmounted,
          setLoading,
          setLoadings,
          setAuthorities,
          setStatuses,
          setStatusesError,
        });
        await _usersFormFunc.getEditParams({
          unmounted,
          actionName,
          id: match.params.id,
          formData,
          setFormData,
          setLoading,
          setdataMounted
        });
      })()
      setInit(false);
    }
  }, [isInit, formData, match.params.id]);

  useEffect(() => {
    if (statusesError) {
      alert('ネットワークエラーが発生しました。ページの再読込をお願いします。');
    }
  }, [statusesError]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="users" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
              <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">ユーザー名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.username}
                            name="username"
                            handleChange={handleChange}
                            fullWidth
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>ユーザー区分</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="authority"
                            menuItems={authorities}
                            loading={loadings.authorities}
                            formData={formData}
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>ステータス</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="status"
                            menuItems={statuses}
                            loading={loadings.statuses}
                            formData={formData}
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">姓</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name}
                            name="last_name"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name}
                            name="first_name"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>姓（カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name_kana}
                            name="last_name_kana"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>名（カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name_kana}
                            name="first_name_kana"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}

export default UsersEdit;