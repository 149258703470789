import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { expenseType } from '../../config/usr-constant';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import DataTable, {
  DataTableRowsProps,
  DataTableSearchInputParamsProps,
  DataTableActionsProps,
  DataTableFuncTbodyRowProps,
  DataTableActionsFuncProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps, DataTableFiltersOptionProps } from '../../components/View/DataTableFilters';
import * as _data_actions from '../../helper/dataActions';
import * as _form from '../../helper/form';
import * as _filter from '../../helper/filter';
import * as _debug from '../../helper/debug';
import * as _usr_const from '../../config/usr-constant';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZshDateTimePicker from '../../components/Form/ZshDateTimePicker';
import axios from 'axios';
import { Expense, UserList } from '../../types/model';

type UnpaidExpensesIndexFormProps = {
  ids: number[];
  scheduled_settlement_date: string | null;
  settlement_date: string | null;
  pickers: {
    scheduled_settlement_date: any;
  }
}

export default function UnpaidExpensesIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [showScheduledSettlementDateDialog, setShowScheduledSettlementDateDialog] = useState<boolean>(false);
  const [formData, setFormData] = useState<UnpaidExpensesIndexFormProps>({
    ids: [],
    scheduled_settlement_date: null,
    settlement_date: null,
    pickers: {
      scheduled_settlement_date: null,
    },
  });
  const [validateErrors, setValidateErrors] = useState<{ [key: string]: string[] }>({});
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState<DataTableActionsFuncProps<Expense> | null>(null);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [userList, setUserList] = useState<DataTableFiltersOptionProps[] | undefined>(undefined);

  // set user list
  useEffect(() => {
    if (typeof userList === "undefined") {
      axios.get(_usr_const.ApiUrl + "users/user-list")
      .then((results: any) => {
        const op: DataTableFiltersOptionProps[] = [];
        if (typeof results.data !== "undefined" && typeof results.data === "object") {
          const options: UserList[] = results.data;
          options.forEach((p: UserList) => {
            op.push({
              text: p.full_name,
              value: p.ID
            })
          })
        }
        setUserList(op);
      })
      .catch(() => {
        setUserList([])
      })
    }
  }, [userList])

  const clickCell = (params: DataTableHandleClickCellProps<Expense>): void => {
    history.push(location.pathname + '/' + params.record.ID + location.search + '&extype=unpaid');
  }

  const handleToCopy = (params: DataTableActionsFuncProps<Expense>): void => {
    if (params.selected.length === 1) {
      history.push('/expenses/add?copy=' + params.selected[0]);
    } else {
      setFlashMessageError('項目を1つ選択してください');
    }
  }

  const handleClickSetScheduledSettlementDate = (params: DataTableActionsFuncProps<Expense>): void => {
    if (params.selected.length > 0) {
      let setDate: boolean = true;
      const selected: number[] = params.selected;
      selected.forEach((id: number) => {
        const selectItem: Expense[] = params.records.filter((d: Expense) => d.ID === id);
        if (selectItem.length > 0) {
          if (typeof selectItem[0].scheduled_settlement_date !== 'undefined') {
            if (selectItem[0].scheduled_settlement_date !== '0001-01-01T00:00:00Z') {
              setDate = false;
            }
          }
          if (typeof selectItem[0].settlement_date !== 'undefined') {
            if (selectItem[0].settlement_date !== '0001-01-01T00:00:00Z') {
              setDate = false;
            }
          }
        }
      });
      if (setDate) {
        setDataTableCallbackParams(params);
        setFormData({
          ...formData,
          ids: selected,
        });
        setShowScheduledSettlementDateDialog(true);
      } else {
        setFlashMessageError('未精算の項目のみを選択してください');
      }
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const confirmDelete = (params: DataTableActionsFuncProps<Expense>): void => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: DataTableActionsFuncProps<Expense>): void => {
    _data_actions.deleteData({
      deleteUrl: 'expenses/delete',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const handleDatePickerChange = (date: Date | null, name: string, type: string): void => {
    const tmpFormData = _form.setDatePickerValue({ formData, date, name, type });
    setFormData(tmpFormData);
  }

  const typeColumn = (params: DataTableFuncTbodyRowProps<Expense>): JSX.Element => {
    return (
      <span>
        {
          typeof params.record.type !== "undefined" && typeof expenseType[params.record.type] !== "undefined" &&
          <span>
            {expenseType[params.record.type]}
          </span>
        }
      </span>
    );
  }

  const handleCloseScheduledSettlementDateDialog = (): void => {
    setFormData({
      ...formData,
      scheduled_settlement_date: null,
      pickers: {
        scheduled_settlement_date: null,
      }
    });
    setShowScheduledSettlementDateDialog(false);
  }

  const handleSubmitScheduledSettlementDateDialog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'expenses/set-scheduled-settlement-date',
      formData,
      history,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors,
      callbackSuccess: () => {
        if (dataTableCallbackParams !== null && typeof dataTableCallbackParams.getData !== 'undefined') {
          dataTableCallbackParams.getData();
        }
        setFormData({
          ...formData,
          scheduled_settlement_date: null,
          pickers: {
            scheduled_settlement_date: null,
          }
        });
        setShowScheduledSettlementDateDialog(false);
      },
      callbackError: () => {
        setFormData({
          ...formData,
          scheduled_settlement_date: null,
          pickers: {
            scheduled_settlement_date: null,
          }
        });
        setShowScheduledSettlementDateDialog(false);
      }
    });
  }

  const callbackGetData = (params: any): void => {
    axios
      .get(_usr_const.ApiUrl + 'expenses/total-price', {
        params: params,
        cancelToken: source.current.token
      })
      .then((results: any) => {
        if (results.data.total !== undefined && !unmounted.current) {
          setTotalPrice(results.data.total)
        }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
      });
  }

  const renderTotalPrice = (): JSX.Element => {
    return (
      <div>
        {_filter.ShFilter(totalPrice, "number_format")}円
      </div>
    );
  }

  const purchaseDateValueElement = (queries: any, filter: DataTableFiltersProps): JSX.Element => {
    let isSet: boolean = false;
    let purchaseDateStart: string = '';
    let purchaseDateEnd: string = '';
    const emptyTitle: string = filter.emptyTitle === undefined ? '全て' : filter.emptyTitle;
    if (queries['purchase_date_start'] !== undefined) {
      purchaseDateStart = queries['purchase_date_start'];
    }
    if (queries['purchase_date_end'] !== undefined) {
      purchaseDateEnd = queries['purchase_date_end'];
    }
    if (purchaseDateStart !== '' || purchaseDateEnd !== '') {
      isSet = true;
    }
    return (
      <div>
        {
          isSet &&
          <span>
            {purchaseDateStart} 〜 {purchaseDateEnd}
          </span>
        }
        {
          isSet === false &&
          <span>{emptyTitle}</span>
        }
      </div>
    );
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  const rows: DataTableRowsProps<Expense>[] = [
    {
      label: '購入日',
      value: 'purchase_date',
      filter: 'YMD',
      sortField: 'purchase_date',
      align: 'left'
    },
    {
      label: '購入者',
      value: 'user_full_name',
      align: 'left'
    },
    {
      label: '種別',
      align: 'left',
      node: typeColumn
    },
    {
      label: '摘要',
      value: 'summary',
      sortField: 'summary',
      align: 'left'
    },
    {
      label: '金額',
      value: 'price',
      filter: 'number_format',
      sortField: 'price',
      align: 'left'
    },
    {
      label: '取引先名',
      value: 'client',
      sortField: 'client',
      align: 'left'
    },
    {
      label: '証憑番号',
      value: 'voucher_number',
      sortField: 'voucher_number',
      align: 'left'
    },
    {
      label: '更新日時',
      value: 'UpdatedAt',
      filter: 'YMDHm',
      sortField: 'updated_at',
      align: 'left',
    },
  ];

  const searchInputParams: DataTableSearchInputParamsProps[] = [
    {
      title: '摘要',
      text: '部分一致',
    },
    {
      title: '取引先名',
      text: '部分一致',
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      title: '購入者',
      key: 'user_id',
      type: 'inlineSelect',
      valueType: 'number',
      options: userList
    },
    {
      title: '購入日',
      type: 'date',
      keys: [
        {
          title: '購入日 開始日',
          key: 'purchase_date_start',
          type: 'date'
        },
        {
          title: '購入日 終了日',
          key: 'purchase_date_end',
          type: 'date'
        }
      ],
      valueElement: purchaseDateValueElement
    },
    {
      title: '合計金額',
      infoElement: renderTotalPrice
    }
  ];

  const actions: DataTableActionsProps<Expense>[] = [
    {
      title: 'コピー',
      func: handleToCopy,
      color: 'primary',
    },
    {
      title: '精算予定日設定',
      func: handleClickSetScheduledSettlementDate,
      color: 'default',
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
    },
  ];

  return (
    <div id="unpaid-expenses" className="content-1">
      <DataTable<Expense>
        rows={rows}
        checkbox={true}
        jsonPath='expenses'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchInputParams={searchInputParams}
        dataTableParams={dataTableParams}
        actions={actions}
        defaultQueryValues={
          {
            type: 'unpaid'
          }
        }
        callbackGetData={callbackGetData}
      />
      <Dialog
        open={showScheduledSettlementDateDialog}
        onClose={handleCloseScheduledSettlementDateDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">精算予定日設定</DialogTitle>
        <DialogContent>
          <form>
            <div className="view-content-in form-content">
              <table className="form-table">
                <tbody>
                  <tr>
                    <th>精算予定日</th>
                    <td>
                      <div className="form-input-group">
                        <ZshDateTimePicker
                          name="scheduled_settlement_date"
                          format="YYYY/MM/DD"
                          views={['date']}
                          value={formData.pickers.scheduled_settlement_date}
                          handleChange={(date: Date | null) => handleDatePickerChange(date, 'scheduled_settlement_date', 'date')}
                          validateErrors={validateErrors}
                          type="date"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseScheduledSettlementDateDialog} color="default">
            閉じる
          </Button>
          <Button
            color="primary"
            onClick={handleSubmitScheduledSettlementDateDialog}
            disabled={(() => {
              if (formData.scheduled_settlement_date !== '') {
                return false;
              }
              return true;
            })()}
          >
            設定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}