import * as _usr_const from './usr-constant';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';

/**
 * Role Allow
 */
let ConstRoleAllow: any = {
  'Expenses': true,
  'UnpaidExpenses': true,
  'ScheduledExpenses': true,
  'PaidedExpenses': true,
  'ExpenseItems': true,
  'TaxProcessings': true,
  'Users': true,
  'Logs': true,
};
if (Object.keys(_usr_const.UserRoleAllow).length > 0) {
  ConstRoleAllow = Object.assign({}, _usr_const.UserRoleAllow);
}
export const RoleAllow: any = ConstRoleAllow;

/**
 * set Menus
 */
export type ChildMenuType = {
  title: string;
  icon?: any;
  to: string;
  name: string;
  allow?: boolean | undefined;
  show?: boolean | undefined;
  active?: boolean | undefined;
  IconComponent?: any;
}
export type MenuType = {
  title: string;
  menuTitle?: any;
  authority?: number | undefined;
  children: ChildMenuType[];
  show?: boolean | undefined;
}
let ConstMenus: MenuType[] = [
  {
    title: '',
    children: [
      {
        title: '経費',
        to: '/expenses',
        name: 'Expenses',
      },
      {
        title: '未精算',
        to: '/unpaid-expenses',
        name: 'UnpaidExpenses',
      },
      {
        title: '精算予定',
        to: '/scheduled-expenses',
        name: 'ScheduledExpenses',
      },
      {
        title: '精算済み',
        to: '/paided-expenses',
        name: 'PaidedExpenses',
      },
      {
        title: '税務処理',
        to: '/tax-processings',
        name: 'TaxProcessings',
      },
    ],
  },
  {
    title: '管理者メニュー',
    authority: 90,
    children: [
      {
        title: '経費科目',
        to: '/expense-items',
        name: 'ExpenseItems',
      },
      {
        title: 'ユーザー',
        icon: PersonIcon,
        to: '/users',
        name: 'Users',
      },
      {
        title: 'ログ',
        icon: DescriptionIcon,
        to: '/logs',
        name: 'Logs',
      },
    ],
  },
];
if (_usr_const.UserMenus.length > 0) {
  ConstMenus = _usr_const.UserMenus;
}
// set menu allow
ConstMenus.forEach((element: any) => {
  element['children'].forEach((child: any) => {
    if (RoleAllow[child.name] !== undefined) {
      if (RoleAllow[child.name] === true) {
        child.allow = true;
      } else {
        child.allow = false;
      }
    } else {
      child.allow = false;
    }
  });
});
export const Menus: any = ConstMenus;

/**
 * set Settings
 */
let setloginRedirect = '/expenses';
if (_usr_const.setloginRedirect !== '') {
  setloginRedirect = _usr_const.setloginRedirect;
}
export const loginRedirect = setloginRedirect;

export const Prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];