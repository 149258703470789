import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import { User } from '../../types/model';

type UserAuthInformation = {
  authority?: number;
}

export default function UsersView({ history, match }: RouteComponentProps<{ id: string }>) {

  const authInformationsSelector: any = useSelector(state => state.AuthInformations);

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<User>({
    ID: 0,
    CreatedAt: null,
    UpdatedAt: null,
    DeletedAt: null,
    parent_id: 0,
    uuid: '',
    username: '',
    authority: undefined,
    status: 0,
    password_setting_date: null,
    is_disable: 0,
    account_locked: null,
    last_failure_datetime: null,
    failure_num: 0,
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    logs: undefined,
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [authInformations, setAuthInformations] = useState<UserAuthInformation | undefined>(undefined);
  const [btnActions, setBtnActions] = useState<HeadButtonGroupBtnActionsProps[] | undefined>(undefined);

  const handleEdit = useCallback(() => {
    history.push('/users/' + match.params.id + '/edit');
  }, [history,  match.params.id]);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<User>({
        unmounted,
        source,
        path: _usr_const.ApiUrl + 'users/' + match.params.id,
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading
      })
      .catch((error: any) => {
        console.log(error);
      });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  // set auth informaitons
  useEffect(() => {
    if (authInformations === undefined) {
      if (authInformationsSelector !== undefined) {
        authInformationsSelector.forEach((element: any) => {
          if (element.isLoded) {
            if (element.error === undefined && element.data !== undefined) {
              if (authInformations !== element.data) {
                setAuthInformations(element.data);
              }
            } else {
              if (typeof element.error !== 'undefined') {
                console.log(element.error); 
              }
            }
          }
        });
      } 
    }
  },
    [
      authInformationsSelector,
      authInformations
    ]
  );

  useEffect(() => {
    if (
      typeof authInformations === 'object' && 
      btnActions === undefined &&
      typeof data.authority !== 'undefined'
    ) {
      let tmpBtnActions: HeadButtonGroupBtnActionsProps[] = [];
      if (typeof authInformations.authority !== 'undefined') {
        if (authInformations.authority >= data.authority) {
          tmpBtnActions = [
            {
              type: 'edit',
              func: handleEdit,
            }
          ];
        }
      }
      setBtnActions(tmpBtnActions);
    }
  }, [
    authInformations,
    btnActions,
    data.authority,
    dataMounted,
    handleEdit
  ]);

  return (
    <div id="users" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.ID}</td>
                  </tr>
                  <tr>
                    <th>ユーザー名</th>
                    <td>{data.username}</td>
                  </tr>
                  <tr>
                    <th>ユーザー区分</th>
                    <td>
                      {
                        _usr_const.userAuthorities[Number(data.authority)] !== undefined &&
                        <span>
                          {_usr_const.userAuthorities[Number(data.authority)]}
                        </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>ステータス</th>
                    <td>
                      {
                        _usr_const.userStatuses[Number(data.status)] !== undefined &&
                        <span>
                          {_usr_const.userStatuses[Number(data.status)]}
                        </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>アカウントロック</th>
                    <td>
                      {
                        data.account_locked !== '0001-01-01T00:00:00Z' &&
                        <span className="red">ロック</span>
                      }
                      {
                        data.account_locked === '0001-01-01T00:00:00Z' &&
                        <span>正常</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>姓</th>
                    <td>{data.last_name}</td>
                  </tr>
                  <tr>
                    <th>名</th>
                    <td>{data.first_name}</td>
                  </tr>
                  <tr>
                    <th>姓（カナ）</th>
                    <td>{data.last_name_kana}</td>
                  </tr>
                  <tr>
                    <th>名（カナ）</th>
                    <td>{data.first_name_kana}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          {
            data.logs !== undefined && data.logs !== null &&
            <ZshLogs logs={data.logs} />
          }
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}