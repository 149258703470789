const initialState = {
  type: '',
  show: false,
}
export default function OverlayProgress(state = initialState, action: any): any {
  switch (action.type) {
    case 'SHOW_OVERLAY_PROGRESS':
      return Object.assign({}, state, {
        type: action.type,
        show: true,
      });
    case 'HIDE_OVERLAY_PROGRESS':
      return Object.assign({}, state, {
        type: action.type,
        show: false,
      });
    default:
        return state;
  }
}